import React, { useState, useEffect } from "react";
import { NavLink as ReactRouterNavLink, Link, useNavigate } from "react-router";
import { Collapse, Navbar, NavItem, NavLink, Nav, Container } from "reactstrap";
import { signOut } from "firebase/auth";
import { firebaseAuth } from "../../firebase/config.js";

const Sidebar = () => {
  const [collapseOpen, setCollapseOpen] = useState(false);
  const [isSigningOut, setIsSigningOut] = useState(false);
  const navigate = useNavigate();

  const toggleCollapse = () => {
    setCollapseOpen((prevState) => !prevState);
    document.body.classList.toggle("nav-open");
  };

  useEffect(() => {
    return () => {
      document.body.classList.remove("nav-open");
    };
  }, []);

  const signUserOut = async () => {
    if (isSigningOut) return;
    try {
      setIsSigningOut(true);
      // Clear any active listeners or subscriptions
      document.body.classList.remove("nav-open");
      setCollapseOpen(false);

      // Sign out from Firebase
      await signOut(firebaseAuth);

      // Small delay to ensure Firebase cleanup
      await new Promise((resolve) => setTimeout(resolve, 100));

      // Navigate to login page
      navigate("/auth/login");
    } catch (err) {
      console.error("Sign out error", err);
      // If we get a 403, still redirect to login as the session is likely expired
      if (err.code === "auth/invalid-user" || err.code === "auth/network-request-failed" || err.message.includes("403")) {
        navigate("/auth/login");
      }
    } finally {
      setIsSigningOut(false);
    }
  };

  return (
    <Navbar className="navbar-vertical fixed-left navbar-light bg-white" expand="md" id="sidenav-main">
      <Container fluid className="mobile-nav-container">
        <div className="mobile-header d-md-none">
          <Link to="../index" className="mobile-brand">
            BET MAGIC
          </Link>

          <button className={`navbar-toggler ${collapseOpen ? "open" : ""}`} onClick={toggleCollapse} aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
        </div>

        <div className="d-none d-md-block desktop-logo-container">
          <Link to="../index" className="desktop-brand">
            BET MAGIC
          </Link>
        </div>

        <Collapse navbar isOpen={collapseOpen} className="mobile-nav">
          <Nav navbar className="navigation-menu">
            <NavItem>
              <NavLink
                tag={ReactRouterNavLink}
                to="../index"
                onClick={() => {
                  setCollapseOpen(false);
                  document.body.classList.remove("nav-open");
                }}
                className="nav-link-custom"
              >
                <div className="nav-link-content">
                  <i className="ni ni-tv-2" />
                  <span className="nav-link-text">Welcome</span>
                </div>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                tag={ReactRouterNavLink}
                to="../americanfootball_nfl"
                onClick={() => {
                  setCollapseOpen(false);
                  document.body.classList.remove("nav-open");
                }}
                className="nav-link-custom"
              >
                <div className="nav-link-content">
                  <i className="fa-solid fa-football text-football-brown" />
                  <span className="nav-link-text">NFL</span>
                </div>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                tag={ReactRouterNavLink}
                to="../basketball_nba"
                onClick={() => {
                  setCollapseOpen(false);
                  document.body.classList.remove("nav-open");
                }}
                className="nav-link-custom"
              >
                <div className="nav-link-content">
                  <i className="fa-solid fa-basketball text-orange" />
                  <span className="nav-link-text">NBA</span>
                </div>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                tag={ReactRouterNavLink}
                to="../americanfootball_ncaaf"
                onClick={() => {
                  setCollapseOpen(false);
                  document.body.classList.remove("nav-open");
                }}
                className="nav-link-custom"
              >
                <div className="nav-link-content">
                  <i className="fa-solid fa-football text-football-brown" />
                  <span className="nav-link-text">NCAAF</span>
                </div>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                tag={ReactRouterNavLink}
                to="../baseball_mlb"
                onClick={() => {
                  setCollapseOpen(false);
                  document.body.classList.remove("nav-open");
                }}
                className="nav-link-custom"
              >
                <div className="nav-link-content">
                  <i className="fa-solid fa-baseball-bat-ball text-baseball-brown" />
                  <span className="nav-link-text">MLB</span>
                </div>
              </NavLink>
            </NavItem>
            <div className="nav-divider" />
            <NavItem>
              <NavLink
                tag={ReactRouterNavLink}
                to="../user-profile"
                onClick={() => {
                  setCollapseOpen(false);
                  document.body.classList.remove("nav-open");
                }}
                className="nav-link-custom"
              >
                <div className="nav-link-content">
                  <i className="ni ni-single-02 text-user-gray" />
                  <span className="nav-link-text">Account</span>
                </div>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                href={process.env.NODE_ENV === "development" ? process.env.REACT_APP_DISCORD_QA_INVITE_LINK : process.env.REACT_APP_DISCORD_PROD_INVITE_LINK}
                target="_blank"
                rel="noopener noreferrer"
                className="nav-link-custom"
                onClick={() => setCollapseOpen(false)}
              >
                <div className="nav-link-content">
                  <i className="fab fa-discord text-discord-blue" />
                  <span className="nav-link-text">Discord</span>
                </div>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                href="mailto:tendies@betmagic.app"
                target="_self"
                rel="noopener noreferrer"
                className="nav-link-custom"
                onClick={() => setCollapseOpen(false)}
              >
                <div className="nav-link-content">
                  <i className="fas fa-life-ring" />
                  <span className="nav-link-text">Support</span>
                </div>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                href="#"
                className="nav-link-custom text-danger"
                onClick={(e) => {
                  e.preventDefault();
                  if (!isSigningOut) {
                    signUserOut();
                  }
                }}
                disabled={isSigningOut}
              >
                <div className="nav-link-content">
                  <i className="fas fa-sign-out-alt" />
                  <span className="nav-link-text">
                    {isSigningOut ? (
                      <>
                        Signing out...
                        <i className="fas fa-spinner fa-spin ml-2" />
                      </>
                    ) : (
                      "Logout"
                    )}
                  </span>
                </div>
              </NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Container>

      <style>
        {`
          /* Sidebar Styles */
          .navbar-vertical {
            box-shadow: 0 0 2rem 0 rgba(136, 152, 170, .15);
            transition: all 0.3s ease;
            padding-top: env(safe-area-inset-top);
            padding-left: env(safe-area-inset-left);
            padding-right: env(safe-area-inset-right);
          }

          /* Mobile Header */
          .mobile-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            padding: 12px 0px;
            height: 56px;
          }

          /* Desktop Logo Container */
          .desktop-logo-container {
            padding: 1.5rem 0 0.5rem;
          }

          .mobile-brand, .desktop-brand {
            color: #32325d;
            font-size: 1.25rem;
            font-weight: 700;
            letter-spacing: 1px;
            text-decoration: none;
            margin: 0;
            padding-left: 1rem;
          }

          .mobile-brand:hover, .desktop-brand:hover {
            color: #32325d;
            text-decoration: none;
          }

          /* Navigation Menu */
          .navigation-menu {
            padding: 1rem 0;
            display: flex;
            flex-direction: column;
          }

          /* Nav Links */
          .nav-link-custom {
            padding: 1rem 1.5rem;
            border-radius: 0.5rem;
            margin: 0.25rem 1rem;
            transition: all 0.3s ease;
            text-align: left;
            width: calc(100% - 2rem);
          }

          .nav-link-custom:hover {
            background: #f6f9fc;
            transform: translateX(5px);
          }

          .nav-link-custom.active {
            background: #5e72e4;
            color: white !important;
            box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08);
          }

          .nav-link-content {
            display: flex;
            align-items: center;
          }

          .nav-link-content i {
            font-size: 1rem;
            margin-right: 1rem;
            width: 1.25rem;
            text-align: center;
          }

          .nav-link-text {
            font-size: 0.875rem;
            font-weight: 500;
          }

          /* Hamburger Menu */
          .navbar {
            padding: 0;
          }
          .navbar-toggler {
            padding: 0;
            width: 30px;
            height: 30px;
            position: relative;
            border: none;
            background: transparent;
            outline: none !important;
          }

          .navbar-toggler-icon {
            position: relative;
            width: 24px;
            height: 2px;
            background: #32325d;
            display: block;
            transition: all 0.3s ease;
          }

          .navbar-toggler-icon::before,
          .navbar-toggler-icon::after {
            content: '';
            position: absolute;
            width: 24px;
            height: 2px;
            background: #32325d;
            left: 0;
            transition: all 0.3s ease;
          }

          .navbar-toggler-icon::before {
            top: -8px;
          }

          .navbar-toggler-icon::after {
            bottom: -8px;
          }

          .navbar-toggler.open .navbar-toggler-icon {
            background: transparent;
          }

          .navbar-toggler.open .navbar-toggler-icon::before {
            top: 0;
            transform: rotate(45deg);
          }

          .navbar-toggler.open .navbar-toggler-icon::after {
            bottom: 0;
            transform: rotate(-45deg);
          }

          /* Mobile Navigation */
          @media (max-width: 768px) {
            .mobile-nav {
              position: fixed;
              top: 56px;
              left: 0;
              right: 0;
              background: white;
              padding: 1rem 0;
              display: none;
              opacity: 0;
              transition: all 0.15s ease;
              max-height: calc(100vh - 56px);
              overflow-y: auto;
            }

            .mobile-nav.show {
              display: block;
              opacity: 1;
            }

            .nav-open {
              overflow: hidden;
            }
          }

          /* Nav Divider */
          .nav-divider {
            height: 1px;
            background-color: #e9ecef;
            margin: 1rem;
          }

          /* Logout Link */
          .nav-link-custom.text-danger {
            color: #dc3545 !important;
          }

          .nav-link-custom.text-danger:hover {
            background-color: #fee2e2 !important;
            color: #dc2626 !important;
          }

          .nav-link-custom:disabled {
            opacity: 0.65;
            cursor: not-allowed;
          }
        `}
      </style>
    </Navbar>
  );
};

export default Sidebar;
