import { Button, Card, CardBody, FormGroup, Form, Input, InputGroupText, InputGroup, Col } from "reactstrap";
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router";
import { firebaseAuth, analytics, updateUserLastLogin } from "../../firebase/config.js";
import { signInWithEmailAndPassword } from "firebase/auth";
import { setUserId, logEvent } from "firebase/analytics";
import AlertPopup from "./AlertPopup.js";
import { isValidEmail } from "../../utils.js";

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState(localStorage.getItem("email") || "");
  const [password, setPassword] = useState("");
  const [alertInfo, setAlertInfo] = useState({ visible: false, type: "danger", message: "" });
  const [rememberMe, setRememberMe] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formErrors, setFormErrors] = useState({
    email: "",
    password: "",
  });

  useEffect(() => {
    // Track page view when component mounts
    logEvent(analytics, "page_view", {
      page_title: "Login Page",
      page_location: window.location.href,
      page_path: window.location.pathname,
    });
  }, []);

  const validateForm = () => {
    let isValid = true;
    const errors = {
      email: "",
      password: "",
    };

    if (!email) {
      errors.email = "Email is required";
      isValid = false;
    } else if (!isValidEmail(email)) {
      errors.email = "Please enter a valid email address";
      isValid = false;
    }

    if (!password) {
      errors.password = "Password is required";
      isValid = false;
    }

    setFormErrors(errors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      // Track validation failure
      logEvent(analytics, "login_validation_failed", {
        email_error: !!formErrors.email,
        password_error: !!formErrors.password,
      });
      return;
    }

    setIsLoading(true);
    try {
      // Track login attempt
      logEvent(analytics, "login_attempt", {
        method: "email",
      });

      const cred = await signInWithEmailAndPassword(firebaseAuth, email, password);

      if (rememberMe) {
        localStorage.setItem("email", email);
        // Track remember me usage
        logEvent(analytics, "remember_me_enabled");
      } else {
        localStorage.removeItem("email");
      }

      // Track successful login
      setUserId(analytics, cred.user.uid);
      logEvent(analytics, "login_success", {
        method: "email",
      });

      // Track if user was returning
      const savedEmail = localStorage.getItem("email");
      if (savedEmail) {
        logEvent(analytics, "returning_user_login", {
          saved_email: true,
        });
      }

      await updateUserLastLogin(cred.user.uid);
      navigate("/dashboard/index");
    } catch (err) {
      // Track login errors
      logEvent(analytics, "login_error", {
        error_code: err.code,
        error_message: err.message,
      });

      const errorMessages = {
        "auth/user-not-found": "No user found with this email",
        "auth/wrong-password": "Invalid password",
        "auth/too-many-requests": "Too many failed login attempts. Please try again later.",
        "auth/user-disabled": "This account has been disabled",
        "auth/invalid-email": "Invalid email format",
      };

      setAlertInfo({
        visible: true,
        type: "danger",
        message: errorMessages[err.code] || "An error occurred during login",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setRememberMe(!!localStorage.getItem("email"));
  }, []);

  const savedEmail = localStorage.getItem("email");

  return (
    <>
      <title>Login - Bet Magic</title>
      <meta name="description" content="Log in to your Bet Magic account to access premium betting insights and real-time notifications." />
      <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
      <meta name="theme-color" content="#5e72e4" />
      <meta name="application-name" content="Bet Magic" />
      <meta property="og:title" content="Login - Bet Magic" />
      <meta property="og:description" content="Access your Bet Magic account for premium betting insights and real-time notifications." />
      <meta property="og:type" content="website" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@betmagic_app" />
      <meta name="twitter:creator" content="@betmagic_app" />
      <meta name="twitter:image" content="https://launch.betmagic.app/static/media/profile-cover.b363fe516aaeb51d46cb.jpg" />
      <meta name="twitter:image:alt" content="Bet Magic Logo" />
      <meta name="twitter:title" content="Login - Bet Magic" />
      <meta name="twitter:description" content="Access your Bet Magic account for premium betting insights and real-time notifications." />
      <link rel="canonical" href={window.location.href} />

      <AlertPopup
        showAlert={alertInfo.visible}
        alertType={alertInfo.type}
        onAlertClick={() => setAlertInfo({ ...alertInfo, visible: false })}
        message={alertInfo.message}
        role="alert"
        aria-live="polite"
      />
      <Col lg="5" md="7">
        <Card className="modern-login-card border-0">
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center mb-5">
              <h1 className="brand-text mb-3">BET MAGIC</h1>
              <p className="text-muted subtitle-text">{savedEmail ? "Let's get back to winning" : "Join the winning team today"}</p>
            </div>
            <Form role="form" className="login-form" onSubmit={handleSubmit} noValidate>
              <FormGroup className="mb-4">
                <label htmlFor="email" className="form-control-label">
                  Email Address
                </label>
                <InputGroup className="input-group-merge">
                  <InputGroupText>
                    <i className="ni ni-email-83" aria-hidden="true" />
                  </InputGroupText>
                  <Input
                    id="email"
                    className="form-control-alternative"
                    value={email}
                    placeholder="name@example.com"
                    type="email"
                    autoComplete="username"
                    name="email"
                    onChange={(e) => setEmail(e.target.value)}
                    aria-label="Email Address"
                    aria-describedby="email-error"
                    aria-invalid={!!formErrors.email}
                    required
                  />
                </InputGroup>
                {formErrors.email && (
                  <div className="text-danger mt-2" id="email-error" role="alert">
                    <small>{formErrors.email}</small>
                  </div>
                )}
              </FormGroup>
              <FormGroup>
                <label htmlFor="password" className="form-control-label">
                  Password
                </label>
                <InputGroup className="input-group-merge">
                  <InputGroupText>
                    <i className="ni ni-lock-circle-open" aria-hidden="true" />
                  </InputGroupText>
                  <Input
                    id="password"
                    className="form-control-alternative"
                    placeholder="Enter your password"
                    value={password}
                    type="password"
                    autoComplete="current-password"
                    name="password"
                    onChange={(e) => setPassword(e.target.value)}
                    aria-label="Password"
                    aria-describedby="password-error"
                    aria-invalid={!!formErrors.password}
                    required
                  />
                </InputGroup>
                {formErrors.password && (
                  <div className="text-danger mt-2" id="password-error" role="alert">
                    <small>{formErrors.password}</small>
                  </div>
                )}
              </FormGroup>
              <div className="d-flex justify-content-between align-items-center mb-4">
                <div className="custom-control custom-control-alternative custom-checkbox">
                  <input
                    className="custom-control-input"
                    id="customCheckLogin"
                    type="checkbox"
                    checked={rememberMe}
                    onChange={(e) => setRememberMe(e.target.checked)}
                    aria-label="Remember my email"
                  />
                  <label className="custom-control-label" htmlFor="customCheckLogin">
                    <span>Remember me</span>
                  </label>
                </div>
                <Link to="../forgot-password" className="text-primary forgot-password">
                  Forgot password?
                </Link>
              </div>
              <div className="text-center">
                <Button
                  className="my-4 w-100 login-button"
                  color="primary"
                  type="submit"
                  disabled={isLoading}
                  aria-label={isLoading ? "Signing in..." : "Sign in"}
                >
                  {isLoading ? (
                    <>
                      <i className="fas fa-spinner fa-spin mr-2" />
                      Signing in...
                    </>
                  ) : (
                    "Sign in"
                  )}
                </Button>
              </div>
            </Form>
            <div className="text-center mt-3">
              <span className="text-muted">Don't have an account? </span>
              <Link to="../register" className="text-primary">
                Create one now
              </Link>
            </div>
          </CardBody>
        </Card>

        <style>
          {`
            .modern-login-card {
              background: white;
              border-radius: 16px;
              box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
            }

            .brand-text {
              color: #32325d;
              font-size: 1.5rem;
              font-weight: 700;
              letter-spacing: 1px;
              text-decoration: none;
              transition: opacity 0.2s ease;
              padding-left: 15px;
            }

            .subtitle-text {
              font-size: 1rem;
              color: #8898aa;
              margin-bottom: 0;
              letter-spacing: 0.3px;
            }

            .form-control-label {
              font-weight: 600;
              font-size: 0.875rem;
              color: #525f7f;
              margin-bottom: 0.5rem;
            }

            .input-group-merge {
              box-shadow: 0 3px 6px rgba(50, 50, 93, 0.1);
              border-radius: 8px;
              transition: box-shadow 0.2s ease;
            }

            .input-group-merge:focus-within {
              box-shadow: 0 4px 12px rgba(50, 50, 93, 0.2);
            }

            .input-group-text {
              border: none !important;
              background: transparent !important;
              color: #8898aa;
              padding-right: 16px;
            }

            .form-control {
              border: none !important;
              font-size: 1rem;
              padding-left: 0;
            }

            .login-button {
              padding: 0.75rem 1.5rem;
              font-size: 1rem;
              border-radius: 8px;
              font-weight: 600;
              transition: all 0.2s ease;
            }

            .login-button:hover:not(:disabled) {
              transform: translateY(-1px);
              box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
            }

            .forgot-password {
              font-size: 0.875rem;
              font-weight: 500;
              text-decoration: none;
              transition: color 0.2s ease;
            }

            .forgot-password:hover {
              color: #2643e9;
              text-decoration: none;
            }

            .custom-control-label {
              font-size: 0.875rem;
              color: #525f7f;
            }

            @media (max-width: 768px) {
              .modern-login-card {
                margin: 1rem;
              }

              .brand-text {
                font-size: 1.75rem;
              }
            }
          `}
        </style>
      </Col>
    </>
  );
};

export default Login;
