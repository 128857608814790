import React from "react";
import { Card, CardBody } from "reactstrap";
import { formatDate, getCellStyle } from "../../utils";
import { useGameData } from "../../hooks/useGameData";

const GameCard = ({ game, viewGame }) => {
  const { gameData, handleClick } = useGameData(game);

  if (!gameData) {
    return null;
  }

  return (
    <Card className="card-stats mb-3 shadow hover-shadow" onClick={() => handleClick(viewGame)} role="button" tabIndex={0}>
      <CardBody className="p-3">
        {/* Game Header with Status */}
        <div className="d-flex justify-content-between align-items-center mb-3">
          <div className="game-teams">
            <h5 className="mb-0 font-weight-bold">{gameData.teams.home}</h5>
            <h5 className="mb-0 text-muted">vs</h5>
            <h5 className="mb-0 font-weight-bold">{gameData.teams.away}</h5>
          </div>
          <div className={`status-pill ${gameData.status.className} text-white px-2 py-1 rounded-pill`}>
            <small className="font-weight-bold">{gameData.status.displayText}</small>
          </div>
        </div>

        {/* Game Info Grid */}
        <div className="game-info-grid">
          <div className="info-item">
            <small className="text-muted d-block">Predicted</small>
            <span className="h4 mb-0 font-weight-bold">{gameData.originalTotal}</span>
          </div>
          <div className="info-item">
            <small className="text-muted d-block">Live Total</small>
            <span className="h4 mb-0 font-weight-bold">{gameData.liveTotal}</span>
          </div>
          <div className="info-item">
            <small className="text-muted d-block">Over</small>
            <span className={`h4 mb-0 font-weight-bold ${getCellStyle(gameData.liveTotal, gameData.overValue, false)}`}>{gameData.overValue}</span>
          </div>
          <div className="info-item">
            <small className="text-muted d-block">Under</small>
            <span className={`h4 mb-0 font-weight-bold ${getCellStyle(gameData.liveTotal, gameData.underValue, true)}`}>{gameData.underValue}</span>
          </div>
        </div>

        {/* Date - Only show for upcoming games */}
        {gameData.gameStatus === "Not Started" && (
          <div className="mt-2 text-center">
            <small className="text-muted">{formatDate(gameData.commenceTime)}</small>
          </div>
        )}
      </CardBody>

      <style>
        {`
          .game-info-grid {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 1rem;
            text-align: center;
          }

          .info-item {
            padding: 0.5rem;
            background: rgba(0,0,0,0.03);
            border-radius: 8px;
          }

          .status-pill {
            font-size: 0.75rem;
            letter-spacing: 0.5px;
          }

          .game-teams h5 {
            font-size: 0.9rem;
            line-height: 1.2;
          }

          .hover-shadow:active {
            transform: scale(0.98);
            transition: transform 0.1s ease;
          }
        `}
      </style>
    </Card>
  );
};

export default GameCard;
