import { useContext } from "react";
import { Navigate, useLocation } from "react-router";
import { UserContext } from "../context/UserContext";

export function AuthWrapper({ children }) {
  const { user, isLoading } = useContext(UserContext);
  const location = useLocation();
  const isDashboardPath = location.pathname.startsWith("/dashboard");
  const isAuthPath = location.pathname.startsWith("/auth");
  const isSubscriptionExpiredPath = location.pathname === "/subscription-expired";

  // Show nothing while loading
  if (isLoading) {
    return null;
  }

  // No user - only allow auth paths
  if (!user) {
    if (isDashboardPath) {
      return <Navigate to="/auth/login" />;
    }
    return children;
  }

  // User exists and account is active - handle subscription expired redirect
  if (user.accountActive && isSubscriptionExpiredPath) {
    return <Navigate to="/dashboard/index" />;
  }

  // User exists but account not active
  if (!user.accountActive) {
    if (isDashboardPath) {
      return <Navigate to="/subscription-expired" />;
    }
    if (isAuthPath) {
      return <Navigate to="/subscription-expired" />;
    }
    return children;
  }

  // User exists and account is active
  if (isAuthPath) {
    return <Navigate to="/dashboard/index" />;
  }
  return children;
}
