import React from "react";
import { Badge, Media } from "reactstrap";
import { formatDate, getCellStyle, formatTime } from "../../utils";
import { useGameData } from "../../hooks/useGameData";

const GameRow = ({ game, viewGame }) => {
  const { gameData, handleClick } = useGameData(game);

  if (!gameData) {
    return null;
  }

  const iconUrl = require(`../../assets/img/theme/${gameData.sportKey}.jpg`);

  return (
    <tr onClick={() => handleClick(viewGame)} className="game-row" role="button" tabIndex={0}>
      {/* Team Names Column */}
      <td className="team-column">
        <Media className="align-items-center">
          <img className="avatar rounded-circle mr-3" alt="Sport icon" src={iconUrl} />
          <Media>
            <div className="teams-container">
              <span className="font-weight-bold text-lg">{gameData.teams.home}</span>
              <span className="text-muted mx-2">vs</span>
              <span className="font-weight-bold text-lg">{gameData.teams.away}</span>
            </div>
          </Media>
        </Media>
      </td>

      {/* Predicted Total Column */}
      <td className="text-center">
        <div className="d-flex flex-column">
          <small className="text-muted mb-1">Predicted</small>
          <span className="font-weight-bold">{gameData.originalTotal}</span>
        </div>
      </td>

      {/* Live Total Column */}
      <td className="text-center">
        <div className="d-flex flex-column">
          <small className="text-muted mb-1">Live</small>
          <span className="font-weight-bold">{gameData.liveTotal}</span>
        </div>
      </td>

      {/* Over Column */}
      <td className="text-center">
        <div className="d-flex flex-column">
          <small className="text-muted mb-1">Over</small>
          <span className={`font-weight-bold ${getCellStyle(gameData.liveTotal, gameData.overValue, false)}`}>{gameData.overValue}</span>
        </div>
      </td>

      {/* Under Column */}
      <td className="text-center">
        <div className="d-flex flex-column">
          <small className="text-muted mb-1">Under</small>
          <span className={`font-weight-bold ${getCellStyle(gameData.liveTotal, gameData.underValue, true)}`}>{gameData.underValue}</span>
        </div>
      </td>

      {/* Date Column */}
      <td className="text-center">
        <div className="d-flex flex-column">
          <small className="text-muted mb-1">Date</small>
          <span className="font-weight-bold">{formatDate(gameData.commenceTime)}</span>
        </div>
      </td>

      {/* Time Column */}
      <td className="text-center">
        <div className="d-flex flex-column">
          <small className="text-muted mb-1">Time</small>
          <span className="font-weight-bold">{formatTime(gameData.commenceTime)}</span>
        </div>
      </td>

      {/* Status Column */}
      <td className="text-center">
        <Badge color={gameData.status.color} className="text-uppercase text-white badge-lg" pill>
          {gameData.status.text}
        </Badge>
      </td>
    </tr>
  );
};

export default GameRow;

<style>
  {`
    .game-row {
      cursor: pointer;
      transition: background-color 0.2s ease;
    }

    .game-row:hover {
      background-color: rgba(0, 0, 0, 0.03);
    }

    .game-row:active {
      background-color: rgba(0, 0, 0, 0.05);
    }

    .team-column {
      min-width: 300px;
    }

    .teams-container {
      display: flex;
      align-items: center;
      font-size: 1rem;
    }

    .badge-lg {
      padding: 0.5rem 1rem;
      font-size: 0.75rem;
      font-weight: 600;
      letter-spacing: 0.5px;
    }

    td {
      vertical-align: middle !important;
    }

    td small {
      font-size: 0.75rem;
      text-transform: uppercase;
      letter-spacing: 0.5px;
    }

    td .font-weight-bold {
      font-size: 1rem;
    }
  `}
</style>;
