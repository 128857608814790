import { Alert } from "reactstrap";
import { useEffect } from "react";

const AlertPopup = ({ showAlert, alertType, onAlertClick, message }) => {
  useEffect(() => {
    let timeoutId;
    if (showAlert) {
      timeoutId = setTimeout(() => {
        onAlertClick();
      }, 3000);
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [showAlert, onAlertClick]);

  const getAlertIcon = (type) => {
    switch (type) {
      case "success":
        return "fas fa-check-circle";
      case "danger":
        return "fas fa-exclamation-circle";
      case "warning":
        return "fas fa-exclamation-triangle";
      case "info":
        return "fas fa-info-circle";
      default:
        return "fas fa-info-circle";
    }
  };

  const getAlertTitle = (type) => {
    switch (type) {
      case "success":
        return "Success!";
      case "danger":
        return "Error";
      case "warning":
        return "Warning";
      case "info":
        return "Information";
      default:
        return "Notice";
    }
  };

  return (
    <Alert isOpen={showAlert} color={alertType} onClick={onAlertClick} className="modern-alert" role="alert" aria-live="polite" toggle={null}>
      <div className="alert-content">
        <i className={`${getAlertIcon(alertType)} alert-icon`} aria-hidden="true" />
        <div className="alert-text">
          <div className="alert-title">{getAlertTitle(alertType)}</div>
          <div className="alert-message">{message}</div>
        </div>
      </div>

      <style>
        {`
          .modern-alert {
            position: fixed;
            top: 20px;
            left: 50%;
            transform: translateX(-50%);
            min-width: 300px;
            max-width: 90%;
            z-index: 9999;
            border: none;
            border-radius: 12px;
            box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
            padding: 1rem;
            animation: slideDown 0.3s ease-out forwards;
            cursor: pointer;
            transition: all 0.2s ease;
          }

          .modern-alert:hover {
            transform: translateX(-50%) translateY(-2px);
            box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
          }

          .alert-content {
            display: flex;
            align-items: flex-start;
            gap: 12px;
          }

          .alert-icon {
            font-size: 1.5rem;
            margin-top: 2px;
          }

          .alert-text {
            flex: 1;
          }

          .alert-title {
            font-weight: 600;
            font-size: 1rem;
            margin-bottom: 2px;
          }

          .alert-message {
            font-size: 0.95rem;
            line-height: 1.4;
          }

          @keyframes slideDown {
            from {
              transform: translateX(-50%) translateY(-100%);
              opacity: 0;
            }
            to {
              transform: translateX(-50%) translateY(0);
              opacity: 1;
            }
          }

          /* Alert Type Specific Styles */
          .alert-success {
            background: #28a745;
            color: white;
          }

          .alert-danger {
            background: #dc3545;
            color: white;
          }

          .alert-warning {
            background: #ffc107;
            color: #000;
          }

          .alert-info {
            background: #17a2b8;
            color: white;
          }

          @media (max-width: 768px) {
            .modern-alert {
              width: 90%;
              min-width: unset;
              margin: 0 auto;
            }

            .alert-icon {
              font-size: 1.25rem;
            }

            .alert-title {
              font-size: 0.95rem;
            }

            .alert-message {
              font-size: 0.9rem;
            }
          }
        `}
      </style>
    </Alert>
  );
};

export default AlertPopup;
