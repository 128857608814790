import React, { useState } from "react";
import { Link } from "react-router";
import { Navbar, Container } from "reactstrap";

const AuthNavbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  const handleLinkClick = () => {
    setIsOpen(false);
  };

  return (
    <Navbar className="auth-navbar" expand="md">
      <Container className="nav-container">
        <Link to="../login" className="navbar-brand">
          BET MAGIC
        </Link>

        <button className={`navbar-toggler ${isOpen ? "open" : ""}`} onClick={toggleNavbar} aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className={`navbar-collapse ${isOpen ? "show" : ""}`}>
          <div className="navbar-nav ms-auto">
            <Link to="../login" className="nav-link" onClick={handleLinkClick}>
              <i className="fas fa-sign-in-alt"></i>
              <span>Login</span>
            </Link>
            <Link to="../register" className="nav-link" onClick={handleLinkClick}>
              <i className="fas fa-user-plus"></i>
              <span>Register</span>
            </Link>
          </div>
        </div>
      </Container>

      <style>
        {`
          .auth-navbar {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            z-index: 100;
            padding: 1rem 0;
            background: transparent;
            transition: all 0.3s ease;
          }

          .nav-container {
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: relative;
          }

          .navbar-brand {
            color: white;
            font-size: 1.5rem;
            font-weight: 700;
            letter-spacing: 1px;
            text-decoration: none;
            transition: opacity 0.2s ease;
            padding-left: 15px;
          }

          .navbar-brand:hover {
            opacity: 0.9;
            color: white;
            text-decoration: none;
          }

          .navbar-toggler {
            padding: 0;
            width: 30px;
            height: 30px;
            position: relative;
            border: none;
            background: transparent;
            outline: none !important;
            display: none;
          }

          .navbar-toggler-icon {
            position: relative;
            width: 24px;
            height: 2px;
            background: white;
            display: block;
            transition: all 0.3s ease;
          }

          .navbar-toggler-icon::before,
          .navbar-toggler-icon::after {
            content: '';
            position: absolute;
            width: 24px;
            height: 2px;
            background: white;
            left: 0;
            transition: all 0.3s ease;
          }

          .navbar-toggler-icon::before {
            top: -8px;
          }

          .navbar-toggler-icon::after {
            bottom: -8px;
          }

          .navbar-toggler.open .navbar-toggler-icon {
            background: transparent;
          }

          .navbar-toggler.open .navbar-toggler-icon::before {
            top: 0;
            transform: rotate(45deg);
          }

          .navbar-toggler.open .navbar-toggler-icon::after {
            bottom: 0;
            transform: rotate(-45deg);
          }

          .navbar-collapse {
            display: flex;
            align-items: center;
            margin-left: auto;
          }

          .navbar-nav {
            display: flex;
            align-items: center;
            gap: 1rem;
          }

          .ms-auto {
            margin-left: auto;
          }

          .nav-link {
            color: rgba(255, 255, 255, 0.9);
            text-decoration: none;
            padding: 0.5rem 1rem;
            border-radius: 6px;
            display: flex;
            align-items: center;
            gap: 0.5rem;
            font-weight: 500;
            transition: all 0.2s ease;
          }

          .nav-link i {
            font-size: 1rem;
          }

          .nav-link:hover {
            color: white;
            background: rgba(255, 255, 255, 0.1);
          }

          @media (max-width: 768px) {
            .auth-navbar {
              padding: 0.75rem 0;
              background: rgb(23, 32, 46);
            }

            .navbar-toggler {
              display: block;
            }

            .navbar-collapse {
              position: fixed;
              top: 56px;
              left: 0;
              right: 0;
              background: rgb(23, 32, 46);
              padding: 1rem;
              display: none;
              opacity: 0;
              transition: all 0.3s ease;
              margin: 0.7rem;
              text-align: center;
            }

            .navbar-collapse.show {
              display: block;
              opacity: 1;
            }

            .navbar-nav {
              flex-direction: column;
              width: 100%;
              gap: 0.75rem;
              align-items: center;
            }

            .nav-link {
              width: auto;
              min-width: 160px;
              justify-content: center;
              padding: 0.75rem 1.5rem;
            }

            .ms-auto {
              margin-left: 0;
            }
          }

          @media (max-width: 576px) {
            .navbar-brand {
              font-size: 1.25rem;
            }

            .nav-link {
              font-size: 0.9rem;
              min-width: 140px;
            }
          }
        `}
      </style>
    </Navbar>
  );
};

export default AuthNavbar;
