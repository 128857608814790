import React from "react";
import { Container } from "reactstrap";

const Header = () => {
  return (
    <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
      <Container fluid>
        <div className="header-body" />
      </Container>
    </div>
  );
};

export default Header;
