import { useState, useRef, useEffect, useMemo } from "react";
import classnames from "classnames";
import "chart.js/auto";
import { Line } from "react-chartjs-2";
import { Card, CardHeader, CardBody, NavItem, NavLink, Nav, Container, Row, Col, Badge } from "reactstrap";
import { options, formatSpreadChartData, formatTotalChartData } from "../variables/charts.js";
import { useParams } from "react-router";
import { useFetchGame } from "../hooks/useFetchGame.js";
import GameHeader from "../components/Headers/GameHeader.js";
import { getGameSpread, getGameTotal, getGameStatus, calculateType, calculatePercentage } from "../utils.js";

const Index = () => {
  const [activeNav, setActiveNav] = useState(1);
  const chartRef = useRef(null);

  const { gameId } = useParams();
  const { game, totals, spreads, error, isLoading } = useFetchGame(gameId);

  // Process spread data - only if we have spreads
  const spreadData = useMemo(() => (spreads?.length ? formatSpreadChartData(spreads) : null), [spreads]);

  // Process total data - only if we have totals and game
  const totalData = useMemo(() => (totals?.length ? formatTotalChartData(totals, game) : null), [totals, game]);

  // Add keyboard shortcuts
  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.target.tagName === "INPUT" || e.target.tagName === "TEXTAREA") return;

      switch (e.key.toLowerCase()) {
        case "t":
          setActiveNav(1);
          break;
        case "s":
          setActiveNav(2);
          break;
        default:
          break;
      }
    };

    document.addEventListener("keydown", handleKeyPress);
    return () => document.removeEventListener("keydown", handleKeyPress);
  }, []); // No dependencies needed since setActiveNav is stable

  const gameStatus = getGameStatus(game);

  // Chart ref handler with cleanup
  const setChartRef = (element) => {
    if (chartRef.current) {
      chartRef.current.destroy();
    }
    chartRef.current = element;

    return () => {
      if (chartRef.current) {
        chartRef.current.destroy();
        chartRef.current = null;
      }
    };
  };

  const getGameTitle = () => {
    if (!game) return "Loading...";
    return (
      <div className="game-title">
        <h2 className="text-dark mb-0">
          {game.home_team} <span className="vs-text">vs</span> {game.away_team}
        </h2>
        <Badge color={gameStatus.color} className="game-status-badge text-white">
          {gameStatus.text}
        </Badge>
      </div>
    );
  };

  // Get meta description
  const getMetaDescription = () => {
    if (!game) return "Loading game details...";
    return `${gameStatus.text} - ${game.home_team} vs ${game.away_team}. Get live odds, scores, and betting insights for this game.`;
  };

  return (
    <>
      <title>{game ? `${game.home_team} vs ${game.away_team} - ${gameStatus.text} | Bet Magic` : "Loading Game | Bet Magic"}</title>
      <meta name="description" content={getMetaDescription()} />
      <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
      <meta name="theme-color" content="#5e72e4" />

      {/* Open Graph / Facebook */}
      <meta property="og:type" content="website" />
      <meta property="og:title" content={game ? `${game.home_team} vs ${game.away_team} - ${gameStatus.text}` : "Game Details"} />
      <meta property="og:description" content={getMetaDescription()} />
      <meta property="og:site_name" content="Bet Magic" />

      {/* Twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@betmagic_app" />
      <meta name="twitter:creator" content="@betmagic_app" />
      <meta name="twitter:image" content="https://launch.betmagic.app/static/media/profile-cover.b363fe516aaeb51d46cb.jpg" />
      <meta name="twitter:image:alt" content="Bet Magic Logo" />
      <meta name="twitter:title" content={game ? `${game.home_team} vs ${game.away_team} - ${gameStatus.text}` : "Game Details"} />
      <meta name="twitter:description" content={getMetaDescription()} />

      {game && !error && (
        <GameHeader
          total={getGameTotal(game, "draftkings")}
          type={calculateType(totals?.[totals.length - 1]?.outcomes?.[0]?.point, getGameTotal(game, "draftkings"))}
          percentage={calculatePercentage(totals?.[totals.length - 1]?.outcomes?.[0]?.point, getGameTotal(game, "draftkings"))}
          spread={getGameSpread(game, "draftkings")}
          scores={game.scores}
        />
      )}

      <Container className="mt--7" fluid>
        <Row>
          <Col className="mb-5 mb-xl-0">
            <Card className="bg-gradient-white shadow">
              {isLoading ? (
                <CardBody>
                  <div className="text-center">
                    <i className="fas fa-spinner fa-spin mr-2" />
                    Loading game data...
                  </div>
                </CardBody>
              ) : error ? (
                <CardBody>
                  <div className="text-center text-danger">
                    <i className="fas fa-exclamation-circle mr-2" />
                    {error}
                  </div>
                </CardBody>
              ) : (
                <>
                  <CardHeader className="bg-transparent">
                    <Row className="align-items-center">
                      <Col xs="12" md="6" className="mb-3 mb-md-0">
                        <h6 className="text-uppercase text-primary ls-1 mb-2">Current Game</h6>
                        {getGameTitle()}
                      </Col>
                      <Col xs="12" md="6">
                        <Nav className="chart-nav" pills>
                          <NavItem>
                            <NavLink
                              className={classnames("chart-nav-link", {
                                active: activeNav === 1,
                              })}
                              onClick={() => setActiveNav(1)}
                              aria-label="Show totals chart (Press 'T')"
                              title="Show totals chart (Press 'T')"
                            >
                              <i className="fas fa-chart-line mr-2" />
                              <span>Total</span>
                              <small className="keyboard-shortcut ml-2">(T)</small>
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              className={classnames("chart-nav-link", {
                                active: activeNav === 2,
                              })}
                              onClick={() => setActiveNav(2)}
                              aria-label="Show spreads chart (Press 'S')"
                              title="Show spreads chart (Press 'S')"
                            >
                              <i className="fas fa-balance-scale mr-2" />
                              <span>Spread</span>
                              <small className="keyboard-shortcut ml-2">(S)</small>
                            </NavLink>
                          </NavItem>
                        </Nav>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <div className="chart-container">
                      {activeNav === 1 && totalData && <Line ref={setChartRef} data={totalData} options={options} />}
                      {activeNav === 2 && spreadData && <Line ref={setChartRef} data={spreadData} options={options} />}
                      {!totalData && !spreadData && <div className="text-center text-muted py-5">No data available for this game yet</div>}
                    </div>
                  </CardBody>
                </>
              )}
            </Card>
          </Col>
        </Row>
      </Container>

      <style>
        {`
          .game-title {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            gap: 1rem;
          }

          .vs-text {
            color: #8898aa;
            font-size: 1rem;
            font-weight: 400;
          }

          .game-status-badge {
            font-size: 0.75rem;
            padding: 0.35em 0.8em;
            font-weight: 600;
            text-transform: uppercase;
            letter-spacing: 0.025em;
          }

          .chart-nav {
            display: flex;
            justify-content: flex-end;
            gap: 0.5rem;
          }

          .chart-nav-link {
            display: flex;
            align-items: center;
            padding: 0.75rem 1.5rem;
            border-radius: 0.375rem;
            transition: all 0.15s ease;
            cursor: pointer;
          }

          .chart-nav-link.active {
            background: #5e72e4;
            color: white;
            box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08);
          }

          .chart-nav-link:not(.active):hover {
            color: #5e72e4;
            background: #f6f9fc;
          }

          .chart-container {
            position: relative;
            width: 100%;
            height: 350px;
            transition: height 0.3s ease;
          }

          .keyboard-shortcut {
            opacity: 0.7;
            font-size: 0.75rem;
            font-weight: normal;
          }

          @media (max-width: 768px) {
            .chart-nav {
              justify-content: center;
              margin-top: 1rem;
            }

            .chart-nav-link {
              padding: 0.5rem 1rem;
            }

            .game-title h2 {
              font-size: 1.5rem;
              text-align: center;
              width: 100%;
            }

            .game-title {
              justify-content: center;
            }

            .text-uppercase {
              text-align: center;
            }

            .keyboard-shortcut {
              display: none;
            }

            .chart-container {
              height: 250px;
            }
          }
        `}
      </style>
    </>
  );
};

export default Index;
