import { Card, CardHeader, CardFooter, Pagination, PaginationItem, PaginationLink, Container, Row, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import { useFetchGames } from "../../hooks/useFetchGames.js";
import { useState, useEffect } from "react";
import GamesTable from "./GameTable.js";
import GamesListHeader from "../../components/Headers/GamesListHeader.js";

const Games = (props) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [hideFinished, setHideFinished] = useState(false);
  const gamesPerPage = 10;

  // Use enhanced hook with league and hideFinished params
  const { todaysGames, upcomingGames, totalTodaysGames, isPending, error } = useFetchGames(props.league, hideFinished);

  // Get league display name for title
  const getLeagueDisplayName = () => {
    switch (props.league) {
      case "americanfootball_nfl":
        return "NFL";
      case "basketball_nba":
        return "NBA";
      case "americanfootball_ncaaf":
        return "NCAAF";
      case "baseball_mlb":
        return "MLB";
      default:
        return "Sports";
    }
  };

  // Format metadata description
  const getMetaDescription = () => {
    const leagueName = getLeagueDisplayName();
    return `Live scores, odds, and upcoming ${leagueName} games. Get real-time updates, betting insights, and game schedules for today's matches and future games.`;
  };

  // Calculate pagination values
  const totalPages = Math.ceil(upcomingGames.length / gamesPerPage);
  const startIndex = (currentPage - 1) * gamesPerPage;
  const paginatedGames = upcomingGames.slice(startIndex, startIndex + gamesPerPage);

  // Reset to page 1 when league changes
  useEffect(() => {
    setCurrentPage(1);
  }, [props.league]);

  const handlePaginationLinkClick = (newPage) => (e) => {
    e.preventDefault();
    if (newPage < 1 || newPage > totalPages) return;
    setCurrentPage(newPage);
  };

  // Add keyboard shortcut for hiding finished games
  useEffect(() => {
    const handleKeyPress = (e) => {
      // Ignore if user is typing in an input field
      if (e.target.tagName === "INPUT" || e.target.tagName === "TEXTAREA") return;

      if (e.key.toLowerCase() === "h") {
        setHideFinished((prev) => !prev);
      }
    };

    document.addEventListener("keydown", handleKeyPress);
    return () => document.removeEventListener("keydown", handleKeyPress);
  }, []);

  // Loading state component
  const LoadingState = () => (
    <div className="text-center py-4">
      <i className="fas fa-spinner fa-spin mr-2" />
      Loading games...
    </div>
  );

  // Error state component
  const ErrorState = () => (
    <div className="text-center py-4 text-danger">
      <i className="fas fa-exclamation-circle mr-2" />
      {error}
    </div>
  );

  return (
    <>
      <title>{`${getLeagueDisplayName()} Games - Live Scores & Odds | Bet Magic`}</title>
      <meta name="description" content={getMetaDescription()} />
      <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
      <meta name="theme-color" content="#5e72e4" />
      <meta name="application-name" content="Bet Magic" />

      {/* Open Graph / Facebook */}
      <meta property="og:type" content="website" />
      <meta property="og:title" content={`${getLeagueDisplayName()} Games - Live Scores & Odds | Bet Magic`} />
      <meta property="og:description" content={getMetaDescription()} />
      <meta property="og:site_name" content="Bet Magic" />

      {/* Twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@betmagic_app" />
      <meta name="twitter:creator" content="@betmagic_app" />
      <meta name="twitter:image" content="https://launch.betmagic.app/static/media/profile-cover.b363fe516aaeb51d46cb.jpg" />
      <meta name="twitter:image:alt" content="Bet Magic Logo" />
      <meta name="twitter:title" content={`${getLeagueDisplayName()} Games - Live Scores & Odds | Bet Magic`} />
      <meta name="twitter:description" content={getMetaDescription()} />

      <GamesListHeader league={props.shortName} currentGames={totalTodaysGames} />
      <Container className="mt--7" fluid>
        {/* Today's Games Section */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0 d-flex justify-content-between align-items-center">
                <h3 className="mb-0">{isPending ? "Loading Today's Games..." : "Today's Games"}</h3>
                {totalTodaysGames > 0 && (
                  <Nav pills>
                    <NavItem>
                      <NavLink
                        className={classnames("py-2 px-3", {
                          active: hideFinished,
                        })}
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          setHideFinished(!hideFinished);
                        }}
                        title="Press 'H' to toggle"
                      >
                        <span className="d-none d-md-block">
                          {hideFinished ? "Show Finished" : "Hide Finished"} <small className="shortcut-hint">(H)</small>
                        </span>
                        <span className="d-md-none">{hideFinished ? "Show Finished" : "Hide Finished"}</span>
                      </NavLink>
                    </NavItem>
                  </Nav>
                )}
              </CardHeader>
              {error ? (
                <ErrorState />
              ) : isPending ? (
                <LoadingState />
              ) : (
                <GamesTable games={todaysGames} tableClassName="table-flush" theadClassName="thead-light" noGameText="No games today" />
              )}
            </Card>
          </div>
        </Row>

        {/* Upcoming Games Section */}
        <Row className="mt-5">
          <div className="col">
            <Card className="bg-default shadow">
              <CardHeader className="bg-transparent border-0">
                <h3 className="text-white mb-0">{isPending ? "Loading Upcoming Games..." : "Upcoming Games"}</h3>
              </CardHeader>
              {error ? (
                <ErrorState />
              ) : isPending ? (
                <LoadingState />
              ) : (
                <>
                  <GamesTable
                    games={paginatedGames}
                    tableClassName="table-dark table-flush"
                    theadClassName="thead-dark"
                    noGameText="Currently no upcoming games"
                  />
                  {upcomingGames.length > 0 && (
                    <CardFooter className="py-4 bg-default">
                      <nav aria-label="Games pagination">
                        <Pagination className="pagination justify-content-end mb-0" listClassName="justify-content-end mb-0">
                          <PaginationItem disabled={currentPage === 1}>
                            <PaginationLink href="#" onClick={handlePaginationLinkClick(currentPage - 1)} aria-label="Previous page">
                              <i className="fas fa-angle-left" />
                              <span className="sr-only">Previous</span>
                            </PaginationLink>
                          </PaginationItem>

                          {/* Show current page number */}
                          <PaginationItem active>
                            <PaginationLink href="#" onClick={(e) => e.preventDefault()}>
                              {currentPage}
                            </PaginationLink>
                          </PaginationItem>

                          <PaginationItem disabled={currentPage >= totalPages}>
                            <PaginationLink href="#" onClick={handlePaginationLinkClick(currentPage + 1)} aria-label="Next page">
                              <i className="fas fa-angle-right" />
                              <span className="sr-only">Next</span>
                            </PaginationLink>
                          </PaginationItem>
                        </Pagination>
                      </nav>
                    </CardFooter>
                  )}
                </>
              )}
            </Card>
          </div>
        </Row>
      </Container>
      <style>
        {`
          .shortcut-hint {
            opacity: 0.6;
            font-size: 0.8em;
            margin-left: 4px;
          }
          @media (max-width: 768px) {
            .shortcut-hint {
              display: none;
            }
          }
        `}
      </style>
    </>
  );
};

export default Games;
