import { Route, Routes, Navigate } from "react-router";
import { Container } from "reactstrap";
import DashboardNavbar from "../components/Navbars/DashboardNavbar";
import DashboardFooter from "../components/Footers/DashboardFooter";
import Sidebar from "../components/Sidebar/Sidebar";

import Index from "../views/Index";
import WelcomePage from "../views/WelcomePage";
import Games from "../views/examples/Games";
import Profile from "../views/examples/Profile";

const Dashboard = () => {
  return (
    <>
      <Sidebar />
      <div className="main-content">
        <DashboardNavbar />
        <Routes>
          <Route path="index" element={<WelcomePage />} />
          <Route path="americanfootball_nfl" element={<Games league="americanfootball_nfl" shortName="NFL" />} />
          <Route path="americanfootball_nfl/:gameId" element={<Index />} />
          <Route path="basketball_nba" element={<Games league="basketball_nba" shortName="NBA" />} />
          <Route path="basketball_nba/:gameId" element={<Index />} />
          <Route path="americanfootball_ncaaf" element={<Games league="americanfootball_ncaaf" shortName="NCAAF" />} />
          <Route path="americanfootball_ncaaf/:gameId" element={<Index />} />
          <Route path="baseball_mlb" element={<Games league="baseball_mlb" shortName="MLB" />} />
          <Route path="baseball_mlb/:gameId" element={<Index />} />
          <Route path="user-profile" element={<Profile />} />
          <Route path="*" element={<Navigate to="/dashboard/index" replace />} />
        </Routes>
        <Container fluid>
          <DashboardFooter />
        </Container>
      </div>
    </>
  );
};

export default Dashboard;
