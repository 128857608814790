import { loadStripe } from "@stripe/stripe-js";
const isDevelopment = process.env.NODE_ENV === "development";
const betMagicStripeBackendUrl = isDevelopment ? "http://localhost:8000" : "https://bmlpayments.com";
const stripeKey = isDevelopment ? process.env.REACT_APP_STRIPE_DEV_PUBLISHABLE_KEY : process.env.REACT_APP_STRIPE_PROD_PUBLISHABLE_KEY;
const stripePromise = loadStripe(stripeKey);

const redirectUserToAccountManagement = async (stripeId) => {
  if (!stripeId) {
    throw new Error("No stripe ID provided");
  }

  try {
    const response = await fetch(`${betMagicStripeBackendUrl}/api/v1/create-customer-portal-session`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ stripeId }),
    });

    const data = await response.json();

    // Check for error response format
    if (data.error) {
      throw new Error(data.error.message || "An error occurred with the payment service");
    }

    // Check for successful response with URL
    if (!data.url) {
      throw new Error("Invalid response from payment service");
    }

    window.location.href = data.url;
  } catch (error) {
    // If it's already our error format, throw it directly
    if (error.message) {
      throw error;
    }

    // Generic error
    throw new Error("Unable to connect to the payment service. Please try again later.");
  }
};

const handleStripePortalRedirect = async (stripeId, setIsLoading, setAlertInfo) => {
  if (!stripeId) {
    setAlertInfo({
      visible: true,
      type: "danger",
      message: "Unable to find your payment information. Please contact support.",
    });
    return;
  }

  try {
    setIsLoading(true);
    await redirectUserToAccountManagement(stripeId);
  } catch (error) {
    setAlertInfo({
      visible: true,
      type: "danger",
      message: "Unable to connect to payment portal. Please try again.",
    });
  } finally {
    setIsLoading(false);
  }
};

async function createSubscription(paymentMethodId, email, firstName, lastName) {
  try {
    const response = await fetch(`${betMagicStripeBackendUrl}/api/create-subscription`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        paymentMethodId,
        email,
        firstName,
        lastName,
      }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error.message || "Subscription creation failed");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error creating subscription:", error);
    throw error;
  }
}

export { stripePromise, betMagicStripeBackendUrl, redirectUserToAccountManagement, createSubscription, handleStripePortalRedirect };
