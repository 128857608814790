import { onSnapshot, query, where } from "firebase/firestore";
import { useState, useEffect, useMemo } from "react";
import { gamesCollection } from "../firebase/config.js";
import { isAfter, isToday, parseISO } from "date-fns";

export const useFetchGames = (league, hideFinished = false) => {
  const [games, setGames] = useState([]);
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState(null);

  // Create a filtered query for the specific league
  useEffect(() => {
    setIsPending(true);
    setError(null);

    try {
      // Filter games by league on the server side
      const gamesQuery = query(gamesCollection, where("sport_key", "==", league));

      const unsub = onSnapshot(
        gamesQuery,
        (snapshot) => {
          // Pre-process dates during initial data fetch
          const newGames = snapshot.docs.map((doc) => {
            const data = doc.data();
            return {
              id: doc.id,
              ...data,
              // Parse date once during fetch
              parsedDate: parseISO(data.commence_time),
            };
          });

          setGames(newGames);
          setIsPending(false);
        },
        (err) => {
          console.error("Error fetching games:", err);
          setError("Failed to fetch games. Please try again later.");
          setIsPending(false);
        }
      );

      return () => unsub();
    } catch (err) {
      console.error("Error setting up games listener:", err);
      setError("Failed to initialize games listener.");
      setIsPending(false);
    }
  }, [league]); // Only re-run if league changes

  // Separate memoization for today's games
  const { todaysGames, totalTodaysGames } = useMemo(() => {
    if (!games?.length) {
      return { todaysGames: [], totalTodaysGames: 0 };
    }

    const filtered = games.reduce(
      (acc, game) => {
        try {
          if (isToday(game.parsedDate)) {
            acc.totalTodaysGames++;
            if (!hideFinished || game.status !== "Finished") {
              acc.todaysGames.push(game);
            }
          }
          return acc;
        } catch (error) {
          console.error("Error processing today's game:", game);
          return acc;
        }
      },
      { todaysGames: [], totalTodaysGames: 0 }
    );

    // Sort by commence_time
    filtered.todaysGames.sort((a, b) => a.parsedDate - b.parsedDate);

    return filtered;
  }, [games, hideFinished]);

  // Separate memoization for upcoming games
  const upcomingGames = useMemo(() => {
    if (!games?.length) return [];

    const today = new Date();
    return games.filter((game) => isAfter(game.parsedDate, today)).sort((a, b) => a.parsedDate - b.parsedDate);
  }, [games]); // Only depends on games array

  return {
    todaysGames,
    upcomingGames,
    totalTodaysGames,
    isPending,
    error,
    // Add total count for debugging/monitoring
    totalGames: games.length,
  };
};
