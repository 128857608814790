import React, { useState, useContext, useEffect } from "react";
import { Button, Card, CardBody, FormGroup, Form, Input, Container, Row, Col, Spinner } from "reactstrap";
import UserHeader from "./../../components/Headers/UserHeader.js";
import { UserContext } from "../../context/UserContext";
import { doc, updateDoc } from "firebase/firestore";
import { firebaseDb } from "../../firebase/config";
import { handleStripePortalRedirect } from "../../stripe/config.js";
import AlertPopup from "./AlertPopup.js";
import { useLocation } from "react-router";

// Move validation function outside component since it's pure
const validateField = (name, value) => {
  let error = "";
  switch (name) {
    case "firstName":
      if (!value.trim()) error = "First name is required";
      else if (value.length < 2) error = "First name must be at least 2 characters";
      break;
    case "lastName":
      if (!value.trim()) error = "Last name is required";
      else if (value.length < 2) error = "Last name must be at least 2 characters";
      break;
    default:
      break;
  }
  return error;
};

// Add helper function for generating secure random state
const generateSecureState = () => {
  const array = new Uint32Array(8);
  window.crypto.getRandomValues(array);
  return Array.from(array, (dec) => ("0" + dec.toString(16)).substr(-2)).join("");
};

// Add helper function for state management
const stateManager = {
  store: (state) => {
    const stateObj = {
      value: state,
      timestamp: Date.now(),
    };
    localStorage.setItem("discord_oauth_state", JSON.stringify(stateObj));
  },
  verify: (state) => {
    try {
      const stored = localStorage.getItem("discord_oauth_state");
      if (!stored) return false;

      const { value, timestamp } = JSON.parse(stored);

      // Check if state is expired (5 minutes)
      if (Date.now() - timestamp > 5 * 60 * 1000) {
        localStorage.removeItem("discord_oauth_state");
        return false;
      }

      return value === state;
    } catch (e) {
      return false;
    } finally {
      localStorage.removeItem("discord_oauth_state");
    }
  },
};

// Add Discord OAuth configuration
const discordConfig = {
  development: {
    clientId: "1125915992598519838",
    redirectUri: "http://localhost:3000/dashboard/user-profile",
  },
  production: {
    clientId: "1126269384130838618",
    redirectUri: "https://launch.betmagic.app/dashboard/user-profile",
  },
};

// Get current environment config
const getDiscordConfig = () => {
  const env = process.env.NODE_ENV === "production" ? "production" : "development";
  return discordConfig[env];
};

const Profile = () => {
  const { user } = useContext(UserContext);
  const location = useLocation();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    stripeId: "",
    discordId: "",
  });
  const [formErrors, setFormErrors] = useState({});
  const [alertInfo, setAlertInfo] = useState({
    visible: false,
    type: "danger",
    message: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isRedirecting, setIsRedirecting] = useState(false);
  const [isTouched, setIsTouched] = useState({});
  const [isLinkingDiscord, setIsLinkingDiscord] = useState(false);

  // Load user data
  useEffect(() => {
    if (user) {
      setFormData({
        firstName: user.firstName || "",
        lastName: user.lastName || "",
        email: user.email || "",
        stripeId: user.stripeId || "",
        discordId: user.discordId || "",
      });
    }
  }, [user]);

  // Update Discord OAuth redirect handler
  useEffect(() => {
    const handleDiscordRedirect = async () => {
      const hash = window.location.hash;
      if (!hash || isLinkingDiscord) return;

      const params = new URLSearchParams(hash.replace("#", ""));
      const accessToken = params.get("access_token");
      const state = params.get("state");

      // Verify state parameter
      if (!state || !stateManager.verify(state)) {
        setAlertInfo({
          visible: true,
          type: "danger",
          message: "Invalid authorization request. Please try again.",
        });
        // Clean up URL
        window.history.replaceState({}, document.title, window.location.pathname);
        return;
      }

      if (!accessToken) return;

      setIsLinkingDiscord(true);
      try {
        // Get Discord user info using the access token
        const response = await fetch("https://discord.com/api/users/@me", {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (!response.ok) {
          throw new Error("Failed to get Discord user info");
        }

        const discordUser = await response.json();

        // Update Firestore with Discord ID
        const userRef = doc(firebaseDb, "users", user.id);
        await updateDoc(userRef, {
          discordId: discordUser.id,
        });

        setFormData((prev) => ({ ...prev, discordId: discordUser.id }));
        setAlertInfo({
          visible: true,
          type: "success",
          message: "Discord account linked successfully!",
        });

        // Clean up URL
        window.history.replaceState({}, document.title, window.location.pathname);
      } catch (error) {
        console.error("Discord linking error:", error);
        setAlertInfo({
          visible: true,
          type: "danger",
          message: "Failed to link Discord account. Please try again.",
        });
      } finally {
        setIsLinkingDiscord(false);
      }
    };

    handleDiscordRedirect();
  }, [user, location, isLinkingDiscord]);

  // Handle field changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    setIsTouched((prev) => ({ ...prev, [name]: true }));

    // Immediate validation
    const error = validateField(name, value);
    setFormErrors((prev) => ({
      ...prev,
      [name]: error,
    }));
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isSubmitting) return;

    // Validate all fields
    const errors = {};
    Object.keys(formData).forEach((key) => {
      if (key !== "email" && key !== "stripeId" && key !== "discordId") {
        const error = validateField(key, formData[key]);
        if (error) errors[key] = error;
      }
    });

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      setAlertInfo({
        visible: true,
        type: "danger",
        message: "Please fix the errors in the form",
      });
      return;
    }

    setIsSubmitting(true);

    try {
      const userRef = doc(firebaseDb, "users", user.id);
      const updates = {
        firstName: formData.firstName.trim(),
        lastName: formData.lastName.trim(),
      };

      await updateDoc(userRef, updates);
      setAlertInfo({
        visible: true,
        type: "success",
        message: "Your account has been updated successfully!",
      });
    } catch (error) {
      console.error("Profile update error:", error);
      setAlertInfo({
        visible: true,
        type: "danger",
        message: error.message || "Failed to update account. Please try again.",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleSubscriptionClick = async () => {
    if (isRedirecting) return;
    await handleStripePortalRedirect(formData.stripeId, setIsRedirecting, setAlertInfo);
  };

  // Update Discord link handler to use environment config
  const handleDiscordLink = () => {
    const state = generateSecureState();
    stateManager.store(state);

    const config = getDiscordConfig();
    const params = new URLSearchParams({
      client_id: config.clientId,
      response_type: "token",
      redirect_uri: config.redirectUri,
      scope: "identify",
      state: state,
    });

    window.location.href = `https://discord.com/oauth2/authorize?${params.toString()}`;
  };

  return (
    <>
      {/* Document Metadata */}
      <title>Profile - Bet Magic</title>
      <meta
        name="description"
        content="Manage your Bet Magic profile settings and preferences. Update your account information and customize your betting experience."
      />
      <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
      <meta name="theme-color" content="#5e72e4" />
      <meta name="application-name" content="Bet Magic" />
      <meta property="og:title" content="Profile - Bet Magic" />
      <meta property="og:description" content="Manage your Bet Magic profile settings and customize your betting experience." />
      <meta property="og:type" content="website" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@betmagic_app" />
      <meta name="twitter:creator" content="@betmagic_app" />
      <meta name="twitter:image" content="https://launch.betmagic.app/static/media/profile-cover.b363fe516aaeb51d46cb.jpg" />
      <meta name="twitter:image:alt" content="Bet Magic Logo" />
      <meta name="twitter:title" content="Profile - Bet Magic" />
      <meta name="twitter:description" content="Manage your Bet Magic profile settings and customize your betting experience." />
      <link rel="canonical" href={window.location.href} />

      <div className="main-content">
        <AlertPopup
          showAlert={alertInfo.visible}
          alertType={alertInfo.type}
          onAlertClick={() => setAlertInfo({ ...alertInfo, visible: false })}
          message={alertInfo.message}
        />
        <UserHeader firstName={formData.firstName} user={user} />

        <div className="profile-container">
          <Container>
            <div className="profile-wrapper">
              {/* Profile Header Card */}
              <Card className="profile-header-card">
                <CardBody>
                  <div className="profile-header-content">
                    <div className="profile-info">
                      <div className="profile-avatar">
                        <div className="avatar-circle">
                          <span className="initials">
                            {formData.firstName.charAt(0)}
                            {formData.lastName.charAt(0)}
                          </span>
                        </div>
                      </div>
                      <div className="profile-details">
                        <h2 className="profile-name">
                          {formData.firstName} {formData.lastName}
                        </h2>
                        <p className="profile-email">{formData.email}</p>
                      </div>
                    </div>
                    {formData.stripeId && (
                      <div className="profile-actions">
                        <Button color="primary" onClick={handleSubscriptionClick} className="subscription-button" disabled={isRedirecting}>
                          {isRedirecting ? (
                            <>
                              <Spinner size="sm" className="button-spinner" />
                              <span>Connecting...</span>
                            </>
                          ) : (
                            <>
                              <i className="fas fa-crown" />
                              <span>Manage Subscription</span>
                            </>
                          )}
                        </Button>
                      </div>
                    )}
                  </div>
                </CardBody>
              </Card>

              {/* Main Form Card */}
              <Card className="profile-form-card">
                <CardBody>
                  <Form role="form" onSubmit={handleSubmit} noValidate>
                    <div className="form-sections">
                      {/* Account Information Section */}
                      <div className="form-section">
                        <div className="section-header">
                          <i className="fas fa-shield-alt section-icon" />
                          <div className="section-title">
                            <h3>Security Details</h3>
                            <p>Manage your account security information</p>
                          </div>
                        </div>
                        <div className="section-content">
                          <Row>
                            <Col md="6">
                              <FormGroup className="form-group-modern">
                                <label className="form-label">Email Address</label>
                                <div className="readonly-field">
                                  <Input value={formData.email} type="email" readOnly disabled />
                                  <i className="fas fa-lock" />
                                </div>
                                <small className="field-hint">Email cannot be changed</small>
                              </FormGroup>
                            </Col>
                            <Col md="6">
                              <FormGroup className="form-group-modern">
                                <label className="form-label">
                                  Discord Integration
                                  <i className="fas fa-info-circle info-icon" title="Connect your Discord account for notifications" />
                                </label>
                                <div className="discord-section">
                                  {formData.discordId ? (
                                    <div className="discord-linked">
                                      <i className="fab fa-discord" />
                                      <span>Discord Account Linked</span>
                                      <div className="discord-badge">
                                        <i className="fas fa-check-circle" />
                                        Connected
                                      </div>
                                    </div>
                                  ) : (
                                    <Button color="primary" onClick={handleDiscordLink} className="discord-button" disabled={isLinkingDiscord}>
                                      {isLinkingDiscord ? (
                                        <>
                                          <Spinner size="sm" className="button-spinner" />
                                          <span>Linking Discord...</span>
                                        </>
                                      ) : (
                                        <>
                                          <i className="fab fa-discord" />
                                          <span>Link Discord Account</span>
                                        </>
                                      )}
                                    </Button>
                                  )}
                                </div>
                              </FormGroup>
                            </Col>
                          </Row>
                        </div>
                      </div>

                      {/* Personal Information Section */}
                      <div className="form-section no-border">
                        <div className="section-header">
                          <i className="fas fa-user section-icon" />
                          <div className="section-title">
                            <h3>Personal Information</h3>
                            <p>Update your personal details</p>
                          </div>
                        </div>
                        <div className="section-content">
                          <Row>
                            <Col md="6">
                              <FormGroup className={`form-group-modern ${formErrors.firstName ? "has-error" : ""}`}>
                                <label className="form-label">First Name</label>
                                <Input
                                  name="firstName"
                                  value={formData.firstName}
                                  placeholder="Enter your first name"
                                  type="text"
                                  required
                                  onChange={handleChange}
                                  aria-invalid={!!formErrors.firstName}
                                  aria-describedby="firstName-error"
                                />
                                {formErrors.firstName && isTouched.firstName && (
                                  <div className="error-message">
                                    <i className="fas fa-exclamation-circle" />
                                    {formErrors.firstName}
                                  </div>
                                )}
                              </FormGroup>
                            </Col>
                            <Col md="6">
                              <FormGroup className={`form-group-modern ${formErrors.lastName ? "has-error" : ""}`}>
                                <label className="form-label">Last Name</label>
                                <Input
                                  name="lastName"
                                  value={formData.lastName}
                                  placeholder="Enter your last name"
                                  type="text"
                                  required
                                  onChange={handleChange}
                                  aria-invalid={!!formErrors.lastName}
                                  aria-describedby="lastName-error"
                                />
                                {formErrors.lastName && isTouched.lastName && (
                                  <div className="error-message">
                                    <i className="fas fa-exclamation-circle" />
                                    {formErrors.lastName}
                                  </div>
                                )}
                              </FormGroup>
                            </Col>
                          </Row>
                        </div>
                      </div>

                      <div className="form-actions">
                        <div className="form-actions-container">
                          <Button
                            type="submit"
                            className={`save-button ${isSubmitting ? "is-loading" : ""}`}
                            color="primary"
                            disabled={isSubmitting || (Object.keys(formErrors).length > 0 && Object.values(formErrors).some((error) => error !== ""))}
                          >
                            <div className="button-content">
                              <i className="fas fa-check check-icon" />
                              <span className="button-text">{isSubmitting ? "Saving Changes" : "Save Changes"}</span>
                            </div>
                            <div className="loading-dots">
                              <span className="dot"></span>
                              <span className="dot"></span>
                              <span className="dot"></span>
                            </div>
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </div>
          </Container>
        </div>
      </div>

      <style>
        {`
          .main-content {
            min-height: 100vh;
            background: linear-gradient(180deg, #f8f9fe 0%, #ffffff 100%);
          }

          .profile-container {
            margin-top: -6rem;
            padding-bottom: 3rem;
          }

          .profile-wrapper {
            max-width: 1000px;
            margin: 0 auto;
          }

          /* Profile Header Card */
          .profile-header-card {
            background: #ffffff;
            border: none;
            border-radius: 1rem;
            box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
            margin-bottom: 2rem;
            overflow: hidden;
          }

          .profile-header-content {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 1.5rem;
          }

          .profile-info {
            display: flex;
            align-items: center;
            gap: 1.5rem;
          }

          .avatar-circle {
            width: 80px;
            height: 80px;
            background: linear-gradient(135deg, #5e72e4 0%, #825ee4 100%);
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .initials {
            color: white;
            font-size: 1.75rem;
            font-weight: 600;
            text-transform: uppercase;
          }

          .profile-details {
            display: flex;
            flex-direction: column;
          }

          .profile-name {
            font-size: 1.5rem;
            font-weight: 600;
            color: #32325d;
            margin: 0;
          }

          .profile-email {
            color: #8898aa;
            margin: 0.25rem 0 0;
            font-size: 0.875rem;
          }

          /* Form Card */
          .profile-form-card {
            background: #ffffff;
            border: none;
            border-radius: 1rem;
            box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
          }

          .form-sections {
            display: flex;
            flex-direction: column;
            gap: 2rem;
          }

          .form-section {
            padding: 1.5rem;
            border-bottom: 1px solid #e9ecef;
          }

          .form-section.no-border {
            border-bottom: none;
          }

          .section-header {
            display: flex;
            align-items: flex-start;
            gap: 1rem;
            margin-bottom: 1.5rem;
          }

          .section-icon {
            font-size: 1.5rem;
            color: #5e72e4;
            background: rgba(94, 114, 228, 0.1);
            padding: 0.75rem;
            border-radius: 0.75rem;
          }

          .section-title h3 {
            font-size: 1.25rem;
            font-weight: 600;
            color: #32325d;
            margin: 0;
          }

          .section-title p {
            color: #8898aa;
            margin: 0.25rem 0 0;
            font-size: 0.875rem;
          }

          /* Form Groups */
          .form-group-modern {
            margin-bottom: 1.5rem;
          }

          .form-label {
            display: flex;
            align-items: center;
            gap: 0.5rem;
            font-size: 0.875rem;
            font-weight: 600;
            color: #32325d;
            margin-bottom: 0.5rem;
          }

          .label-badge {
            background: #2dce89;
            color: white;
            font-size: 0.75rem;
            padding: 0.25rem 0.5rem;
            border-radius: 0.25rem;
            font-weight: 500;
          }

          .info-icon {
            color: #8898aa;
            font-size: 0.875rem;
            cursor: help;
          }

          .readonly-field {
            position: relative;
          }

          .readonly-field input {
            padding-right: 2.5rem;
            background-color: #f6f9fc;
            color: #8898aa;
          }

          .readonly-field i {
            position: absolute;
            right: 1rem;
            top: 50%;
            transform: translateY(-50%);
            color: #8898aa;
          }

          input.form-control {
            height: 3rem;
            padding: 0.75rem 1rem;
            font-size: 0.875rem;
            line-height: 1.5;
            border: 1px solid #e9ecef;
            border-radius: 0.5rem;
            transition: all 0.2s ease;
          }

          input.form-control:focus {
            border-color: #5e72e4;
            box-shadow: 0 0 0 0.2rem rgba(94, 114, 228, 0.25);
          }

          .has-error input.form-control {
            border-color: #fb6340;
          }

          .has-error input.form-control:focus {
            box-shadow: 0 0 0 0.2rem rgba(251, 99, 64, 0.25);
          }

          .error-message {
            display: flex;
            align-items: center;
            gap: 0.375rem;
            color: #fb6340;
            font-size: 0.75rem;
            margin-top: 0.375rem;
          }

          .field-hint {
            color: #8898aa;
            font-size: 0.75rem;
            margin-top: 0.375rem;
          }

          /* Buttons */
          .subscription-button {
            display: inline-flex;
            align-items: center;
            gap: 0.5rem;
            height: 2.5rem;
            padding: 0 1.25rem;
            font-size: 0.875rem;
            font-weight: 600;
            border-radius: 0.5rem;
            transition: all 0.2s ease;
          }

          .subscription-button i {
            font-size: 1rem;
          }

          .form-actions {
            padding: 1.5rem;
            background: white;
            border-top: 1px solid #e9ecef;
            border-radius: 0 0 1rem 1rem;
          }

          .form-actions-container {
            display: flex;
            justify-content: flex-end;  /* Right-aligned on desktop */
            width: 100%;
          }

          .save-button {
            position: relative;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            height: 2.75rem;
            padding: 0 1.5rem;
            font-size: 0.875rem;
            font-weight: 600;
            color: white;
            background: #5e72e4;
            border: none;
            border-radius: 0.5rem;
            transition: all 0.2s ease;
            min-width: 160px;
            overflow: hidden;
          }

          .button-content {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 0.5rem;
            width: 100%;
            height: 100%;
          }

          .spinner-container {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 16px;
            height: 16px;
          }

          .save-button:not(:disabled):hover {
            transform: translateY(-1px);
            box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
            background: #485fe0;
          }

          .save-button:disabled {
            opacity: 0.65;
            cursor: not-allowed;
            background: #5e72e4;
          }

          .button-spinner {
            margin-right: 0.5rem;
          }

          /* Mobile Styles */
          @media (max-width: 767.98px) {
            .profile-header-content {
              flex-direction: column;
              text-align: center;
              gap: 1.5rem;
            }

            .profile-info {
              flex-direction: column;
              gap: 1rem;
            }

            .section-header {
              flex-direction: column;
              align-items: center;
              text-align: center;
              gap: 0.75rem;
            }

            .form-section {
              padding: 1.25rem;
            }

            .form-actions {
              padding: 1rem;
            }

            .form-actions-container {
              justify-content: center;  /* Centered on mobile */
            }

            .save-button {
              width: 100%;
              max-width: 300px;
            }
          }

          @media (max-width: 575.98px) {
            .profile-name {
              font-size: 1.25rem;
            }

            .avatar-circle {
              width: 64px;
              height: 64px;
            }

            .initials {
              font-size: 1.5rem;
            }

            .section-title h3 {
              font-size: 1.125rem;
            }

            input.form-control {
              height: 2.75rem;
            }
          }

          @media (prefers-reduced-motion: reduce) {
            .subscription-button,
            .save-button,
            input.form-control {
              transition: none;
            }
          }

          .discord-section {
            margin-top: 0.5rem;
          }

          .discord-button {
            display: inline-flex;
            align-items: center;
            gap: 0.75rem;
            padding: 0.75rem 1.5rem;
            background: #5865F2;
            border: none;
            border-radius: 8px;
            font-weight: 600;
            transition: all 0.2s ease;
          }

          .discord-button:hover:not(:disabled) {
            background: #4752C4;
            transform: translateY(-1px);
            box-shadow: 0 7px 14px rgba(88, 101, 242, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
          }

          .discord-button i {
            font-size: 1.25rem;
          }

          .discord-linked {
            display: flex;
            align-items: center;
            gap: 0.75rem;
            padding: 0.75rem 1rem;
            background: rgba(88, 101, 242, 0.1);
            border-radius: 8px;
            color: #5865F2;
            font-weight: 500;
          }

          .discord-linked i {
            font-size: 1.25rem;
          }

          .discord-badge {
            margin-left: auto;
            display: flex;
            align-items: center;
            gap: 0.375rem;
            padding: 0.25rem 0.75rem;
            background: rgba(45, 206, 137, 0.1);
            border-radius: 20px;
            color: #2dce89;
            font-size: 0.875rem;
            font-weight: 600;
          }

          .discord-badge i {
            font-size: 0.875rem;
          }

          @media (max-width: 768px) {
            .discord-linked {
              flex-direction: column;
              text-align: center;
              padding: 1rem;
            }

            .discord-badge {
              margin: 0.5rem 0 0;
            }
          }
        `}
      </style>
    </>
  );
};

export default Profile;
