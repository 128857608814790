import { getGameTotal, getLineValue, formatTime } from "../utils";
import { GAME_STATUS_STYLES } from "../constants/gameConstants";

export const useGameData = (game) => {
  const gameData = !game
    ? null
    : {
        originalTotal: getGameTotal(game, "draftkings") || "N/A",
        liveTotal: game.liveTotal || "N/A",
        overValue: getLineValue(game.sport_key, getGameTotal(game, "draftkings"), false) || "N/A",
        underValue: getLineValue(game.sport_key, getGameTotal(game, "draftkings"), true) || "N/A",
        status: !GAME_STATUS_STYLES[game.status]
          ? null
          : {
              ...GAME_STATUS_STYLES[game.status],
              displayText: GAME_STATUS_STYLES[game.status].useTime ? formatTime(game.commence_time) : GAME_STATUS_STYLES[game.status].text,
            },
        teams: {
          home: game.home_team,
          away: game.away_team,
        },
        sportKey: game.sport_key,
        commenceTime: game.commence_time,
        gameStatus: game.status,
        formattedTime: formatTime(game.commence_time),
      };

  const handleClick = (viewGame) => {
    if (typeof viewGame === "function") {
      viewGame();
    }
  };

  return { gameData, handleClick };
};
