import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router";

const OfflineHandler = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // Check initial online status
    if (!navigator.onLine && location.pathname !== "/offline") {
      navigate("/offline", { state: { from: location.pathname } });
    }

    const handleOffline = () => {
      if (location.pathname !== "/offline") {
        navigate("/offline", { state: { from: location.pathname } });
      }
    };

    const handleOnline = () => {
      if (location.pathname === "/offline") {
        // Get the stored path or default to dashboard
        const previousPath = location.state?.from || "/dashboard/index";
        navigate(previousPath);
      }
    };

    window.addEventListener("offline", handleOffline);
    window.addEventListener("online", handleOnline);

    return () => {
      window.removeEventListener("offline", handleOffline);
      window.removeEventListener("online", handleOnline);
    };
  }, [navigate, location]);

  return null;
};

export default OfflineHandler;
