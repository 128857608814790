import React from "react";
import { Card, CardBody, CardTitle, Row, Col } from "reactstrap";
import "./ModernCard.css";

const SpreadValue = ({ name, point }) => (
  <div className="spread-value">
    <span className="team-name">{name || "N/A"}</span>
    <span className="point-value">{point || "N/A"}</span>
  </div>
);

const OriginalSpreadCard = ({
  spread = [
    { name: "N/A", point: "N/A" },
    { name: "N/A", point: "N/A" },
  ],
}) => (
  <Card className="modern-card">
    <CardBody>
      <Row className="align-items-center">
        <Col>
          <CardTitle tag="h6" className="text-uppercase text-muted mb-2 font-weight-bold">
            Original Spread
          </CardTitle>
          <div className="spread-container">
            <SpreadValue name={spread?.[0]?.name} point={spread?.[0]?.point} />
            <SpreadValue name={spread?.[1]?.name} point={spread?.[1]?.point} />
          </div>
        </Col>
        <Col xs="auto">
          <div className="icon-container">
            <i className="fas fa-balance-scale" />
          </div>
        </Col>
      </Row>
    </CardBody>
  </Card>
);

export default OriginalSpreadCard;
