import { Button, Card, CardBody, FormGroup, Form, Input, InputGroupText, InputGroup, Col } from "reactstrap";
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router";

import { firebaseAuth } from "../../firebase/config.js";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { setDoc, doc, serverTimestamp } from "firebase/firestore";
import { firebaseDb, analytics } from "../../firebase/config";
import { setUserId, logEvent } from "firebase/analytics";
import AlertPopup from "./AlertPopup.js";
import { isValidEmail } from "../../utils.js";

import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { createSubscription } from "../../stripe/config.js";

const Register = () => {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();

  const [isProcessing, setIsProcessing] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    agreedToLegal: false,
  });

  const [formErrors, setFormErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    card: "",
  });

  const [passwordState, setPasswordState] = useState({
    status: "invalid",
    message: "Password does not meet criteria",
  });

  const [alertInfo, setAlertInfo] = useState({
    visible: false,
    type: "danger",
    message: "",
  });

  useEffect(() => {
    // Track registration page view
    logEvent(analytics, "begin_registration", {
      page_title: "Registration Page",
      page_location: window.location.href,
      page_path: window.location.pathname,
    });
  }, []);

  const validatePassword = (password) => {
    const regex = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])(?=\S+$).{8,}$/;
    const isValid = regex.test(password);
    setPasswordState({
      status: isValid ? "valid" : "invalid",
      message: isValid ? "Password is valid" : "Password does not meet criteria",
    });
    return isValid;
  };

  const validateForm = () => {
    let isValid = true;
    const errors = {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      card: "",
    };

    if (!formData.firstName.trim()) {
      errors.firstName = "First name is required";
      isValid = false;
    }
    if (!formData.lastName.trim()) {
      errors.lastName = "Last name is required";
      isValid = false;
    }

    if (!formData.email) {
      errors.email = "Email is required";
      isValid = false;
    } else if (!isValidEmail(formData.email)) {
      errors.email = "Please enter a valid email address";
      isValid = false;
    }

    if (!validatePassword(formData.password)) {
      errors.password = "Password does not meet requirements";
      isValid = false;
    }

    if (!formData.agreedToLegal) {
      setAlertInfo({
        visible: true,
        type: "danger",
        message: "Please agree to the Terms of Use and Privacy Policy",
      });
      isValid = false;
    }

    setFormErrors(errors);

    // Track form validation results
    if (!isValid) {
      logEvent(analytics, "registration_validation_failed", {
        firstName_error: !!errors.firstName,
        lastName_error: !!errors.lastName,
        email_error: !!errors.email,
        password_error: !!errors.password,
        card_error: !!errors.card,
      });
    }

    return isValid;
  };

  const handleChange = (e) => {
    const { name, value, checked, type } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: type === "checkbox" ? checked : value,
    }));

    if (formErrors[name]) {
      setFormErrors((prev) => ({ ...prev, [name]: "" }));
    }

    if (name === "password") {
      validatePassword(value);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isProcessing) return;

    setAlertInfo({ visible: false, type: "danger", message: "" });

    if (!stripe || !elements) {
      setAlertInfo({
        visible: true,
        type: "danger",
        message: "Payment system is not ready. Please try again.",
      });
      return;
    }

    if (!validateForm()) return;

    setIsProcessing(true);

    try {
      // Track payment initiation
      logEvent(analytics, "begin_payment", {
        currency: "USD",
        value: 30.0,
      });

      const cardElement = elements.getElement(CardElement);
      const { error: paymentMethodError, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
        billing_details: {
          name: `${formData.firstName} ${formData.lastName}`,
          email: formData.email,
        },
      });

      if (paymentMethodError) {
        // Track payment method error
        logEvent(analytics, "payment_error", {
          error_type: "payment_method_creation",
          error_message: paymentMethodError.message,
        });
        throw new Error(paymentMethodError.message);
      }

      const subscriptionData = await createSubscription(paymentMethod.id, formData.email, formData.firstName, formData.lastName);

      const { clientSecret, status, customerId } = subscriptionData;

      if (status === "requires_action") {
        const { error: confirmError } = await stripe.confirmCardPayment(clientSecret);
        if (confirmError) {
          // Track payment confirmation error
          logEvent(analytics, "payment_error", {
            error_type: "payment_confirmation",
            error_message: confirmError.message,
          });
          throw new Error(confirmError.message);
        }
      }

      if (status === "succeeded" || status === "requires_action") {
        // Track successful payment
        logEvent(analytics, "payment_success", {
          currency: "USD",
          value: 30.0,
          payment_type: "card",
        });
        await createUserAccount(customerId);
      } else {
        throw new Error(`Unexpected payment status: ${status}`);
      }
    } catch (err) {
      // Track registration error
      logEvent(analytics, "registration_error", {
        error_message: err.message,
        registration_step: isProcessing ? "payment" : "form_submission",
      });

      setAlertInfo({
        visible: true,
        type: "danger",
        message: `Registration failed: ${err.message}`,
      });
      elements.getElement(CardElement).clear();
    } finally {
      setIsProcessing(false);
    }
  };

  const createUserAccount = async (customerId) => {
    try {
      const cred = await createUserWithEmailAndPassword(firebaseAuth, formData.email, formData.password);
      const user = cred.user;

      // Set analytics user ID
      setUserId(analytics, user.uid);

      // Create user document
      await setDoc(doc(firebaseDb, "users", user.uid), {
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email,
        stripeId: customerId,
        accountActive: true,
        createdAt: serverTimestamp(),
        lastLogin: serverTimestamp(),
      });

      // Track successful registration
      logEvent(analytics, "registration_complete", {
        method: "email",
        success: true,
      });

      // Track subscription start
      logEvent(analytics, "subscription_start", {
        currency: "USD",
        value: 30.0,
        subscription_type: "premium",
      });

      setAlertInfo({
        visible: true,
        type: "success",
        message: "Registration successful! Redirecting to dashboard...",
      });

      setTimeout(() => navigate("/dashboard/index"), 2000);
    } catch (err) {
      // Track account creation error
      logEvent(analytics, "registration_error", {
        error_type: "account_creation",
        error_message: err.message,
      });
      throw new Error(err.message);
    }
  };

  return (
    <>
      {/* Document Metadata */}
      <title>Create Account - Bet Magic</title>
      <meta
        name="description"
        content="Join Bet Magic today. Create your account to access premium betting insights, real-time notifications, and join our exclusive community."
      />
      <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
      <meta name="theme-color" content="#5e72e4" />
      <meta name="application-name" content="Bet Magic" />
      <meta property="og:title" content="Join Bet Magic - Premium Betting Insights" />
      <meta property="og:description" content="Create your Bet Magic account for exclusive access to premium betting insights and real-time notifications." />
      <meta property="og:type" content="website" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@betmagic_app" />
      <meta name="twitter:creator" content="@betmagic_app" />
      <meta name="twitter:image" content="https://launch.betmagic.app/static/media/profile-cover.b363fe516aaeb51d46cb.jpg" />
      <meta name="twitter:image:alt" content="Bet Magic Logo" />
      <meta name="twitter:title" content="Join Bet Magic - Premium Betting Insights" />
      <meta name="twitter:description" content="Create your Bet Magic account for exclusive access to premium betting insights and real-time notifications." />
      <link rel="canonical" href={window.location.href} />

      <AlertPopup
        showAlert={alertInfo.visible}
        alertType={alertInfo.type}
        onAlertClick={() => setAlertInfo({ ...alertInfo, visible: false })}
        message={alertInfo.message}
        role="alert"
        aria-live="polite"
      />
      <Col lg="6" md="8">
        <Card className="modern-login-card border-0">
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center mb-5">
              <h1 className="brand-text mb-3">BET MAGIC</h1>
              <p className="text-muted subtitle-text">Create your account and start winning today</p>
            </div>

            <Form role="form" className="register-form" onSubmit={handleSubmit} noValidate>
              {/* Personal Information Section */}
              <div className="form-section mb-4">
                <h6 className="section-title mb-4">Personal Information</h6>
                <div className="row">
                  <div className="col-md-6">
                    <FormGroup>
                      <label htmlFor="firstName" className="form-control-label">
                        First Name
                      </label>
                      <InputGroup className="input-group-merge">
                        <InputGroupText>
                          <i className="ni ni-single-02" aria-hidden="true" />
                        </InputGroupText>
                        <Input
                          id="firstName"
                          className="form-control-alternative"
                          placeholder="John"
                          type="text"
                          name="firstName"
                          value={formData.firstName}
                          onChange={handleChange}
                          aria-invalid={!!formErrors.firstName}
                          required
                        />
                      </InputGroup>
                      {formErrors.firstName && (
                        <div className="text-danger mt-2" role="alert">
                          <small>{formErrors.firstName}</small>
                        </div>
                      )}
                    </FormGroup>
                  </div>
                  <div className="col-md-6">
                    <FormGroup>
                      <label htmlFor="lastName" className="form-control-label">
                        Last Name
                      </label>
                      <InputGroup className="input-group-merge">
                        <InputGroupText>
                          <i className="ni ni-single-02" aria-hidden="true" />
                        </InputGroupText>
                        <Input
                          id="lastName"
                          className="form-control-alternative"
                          placeholder="Doe"
                          type="text"
                          name="lastName"
                          value={formData.lastName}
                          onChange={handleChange}
                          aria-invalid={!!formErrors.lastName}
                          required
                        />
                      </InputGroup>
                      {formErrors.lastName && (
                        <div className="text-danger mt-2" role="alert">
                          <small>{formErrors.lastName}</small>
                        </div>
                      )}
                    </FormGroup>
                  </div>
                </div>

                <FormGroup>
                  <label htmlFor="email" className="form-control-label">
                    Email Address
                  </label>
                  <InputGroup className="input-group-merge">
                    <InputGroupText>
                      <i className="ni ni-email-83" aria-hidden="true" />
                    </InputGroupText>
                    <Input
                      id="email"
                      className="form-control-alternative"
                      placeholder="name@example.com"
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      aria-invalid={!!formErrors.email}
                      required
                    />
                  </InputGroup>
                  {formErrors.email && (
                    <div className="text-danger mt-2" role="alert">
                      <small>{formErrors.email}</small>
                    </div>
                  )}
                </FormGroup>

                <FormGroup>
                  <label htmlFor="password" className="form-control-label">
                    Password
                  </label>
                  <InputGroup className="input-group-merge">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" aria-hidden="true" />
                    </InputGroupText>
                    <Input
                      id="password"
                      className="form-control-alternative"
                      placeholder="Create a secure password"
                      type="password"
                      name="password"
                      value={formData.password}
                      onChange={handleChange}
                      aria-invalid={passwordState.status === "invalid"}
                      required
                    />
                  </InputGroup>
                  <div className="password-requirements mt-3">
                    <small className="text-muted">Password must contain:</small>
                    <ul className="requirements-list">
                      <li className={formData.password.length >= 8 ? "text-success" : "text-muted"}>
                        <i className={`fas fa-${formData.password.length >= 8 ? "check" : "circle"} mr-2`} />
                        At least 8 characters
                      </li>
                      <li className={/[A-Z]/.test(formData.password) ? "text-success" : "text-muted"}>
                        <i className={`fas fa-${/[A-Z]/.test(formData.password) ? "check" : "circle"} mr-2`} />
                        One uppercase letter
                      </li>
                      <li className={/[a-z]/.test(formData.password) ? "text-success" : "text-muted"}>
                        <i className={`fas fa-${/[a-z]/.test(formData.password) ? "check" : "circle"} mr-2`} />
                        One lowercase letter
                      </li>
                      <li className={/[0-9]/.test(formData.password) ? "text-success" : "text-muted"}>
                        <i className={`fas fa-${/[0-9]/.test(formData.password) ? "check" : "circle"} mr-2`} />
                        One number
                      </li>
                      <li className={/[!@#$%^&*]/.test(formData.password) ? "text-success" : "text-muted"}>
                        <i className={`fas fa-${/[!@#$%^&*]/.test(formData.password) ? "check" : "circle"} mr-2`} />
                        One special character (!@#$%^&*)
                      </li>
                    </ul>
                  </div>
                </FormGroup>
              </div>

              {/* Payment Section */}
              <div className="form-section mb-4">
                <h6 className="section-title mb-4">Payment Information</h6>
                <div className="subscription-info mb-4">
                  <div className="price-card">
                    <div className="price-content">
                      <div className="price-badge">PREMIUM</div>
                      <div className="price-header">
                        <h3 className="price-amount">
                          $30<span className="price-period">/month</span>
                        </h3>
                      </div>
                      <div className="price-features">
                        <ul>
                          <li>
                            <i className="fas fa-check-circle text-success mr-2"></i>
                            Access to Premium Picks
                          </li>
                          <li>
                            <i className="fas fa-check-circle text-success mr-2"></i>
                            Discord Community Access
                          </li>
                          <li>
                            <i className="fas fa-check-circle text-success mr-2"></i>
                            Real-time Discord Notifications
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                <FormGroup className="payment-form-group">
                  <label htmlFor="card-element" className="form-control-label">
                    <i className="fas fa-credit-card mr-2"></i>
                    Card Details
                  </label>
                  <div className="card-element-wrapper">
                    <div className="card-element-container">
                      <CardElement
                        id="card-element"
                        options={{
                          style: {
                            base: {
                              fontSize: "16px",
                              color: "#525f7f",
                              fontFamily: '"Open Sans", sans-serif',
                              fontSmoothing: "antialiased",
                              "::placeholder": {
                                color: "#8898aa",
                              },
                              iconColor: "#5e72e4",
                              letterSpacing: "0.025em",
                              "::selection": {
                                backgroundColor: "#e6ebf1",
                              },
                            },
                            invalid: {
                              color: "#f5365c",
                              iconColor: "#f5365c",
                            },
                          },
                          hidePostalCode: true,
                        }}
                        onChange={(e) => {
                          if (e.error) {
                            setFormErrors((prev) => ({
                              ...prev,
                              card: e.error.message,
                            }));
                          } else {
                            setFormErrors((prev) => ({
                              ...prev,
                              card: "",
                            }));
                          }
                        }}
                      />
                    </div>
                    <div className="card-brands">
                      <i className="fab fa-cc-visa" title="Visa"></i>
                      <i className="fab fa-cc-mastercard" title="Mastercard"></i>
                      <i className="fab fa-cc-amex" title="American Express"></i>
                      <i className="fab fa-cc-discover" title="Discover"></i>
                    </div>
                  </div>
                  {formErrors.card && (
                    <div className="text-danger mt-2" role="alert">
                      <small>
                        <i className="fas fa-exclamation-circle mr-1"></i>
                        {formErrors.card}
                      </small>
                    </div>
                  )}
                  <div className="payment-security mt-3">
                    <small className="text-muted">
                      <i className="fas fa-lock mr-1"></i>
                      Your payment information is secured with SSL encryption
                    </small>
                  </div>
                </FormGroup>
              </div>

              {/* Terms and Conditions */}
              <div className="form-section mb-4">
                <div className="custom-control custom-checkbox">
                  <input
                    className="custom-control-input"
                    id="agreedToLegal"
                    name="agreedToLegal"
                    type="checkbox"
                    checked={formData.agreedToLegal}
                    onChange={handleChange}
                    required
                  />
                  <label className="custom-control-label" htmlFor="agreedToLegal">
                    <span>
                      I agree to the{" "}
                      <a href="https://betmagic.app/terms-of-use/" target="_blank" rel="noopener noreferrer">
                        Terms of Use
                      </a>{" "}
                      and{" "}
                      <a href="https://betmagic.app/privacy-policy/" target="_blank" rel="noopener noreferrer">
                        Privacy Policy
                      </a>
                    </span>
                  </label>
                </div>
              </div>

              {/* Submit Button */}
              <div className="text-center">
                <Button className="my-4 w-100 login-button" color="primary" type="submit" disabled={isProcessing}>
                  {isProcessing ? (
                    <>
                      <i className="fas fa-spinner fa-spin mr-2" />
                      Processing...
                    </>
                  ) : (
                    "Complete Registration"
                  )}
                </Button>
              </div>
            </Form>

            <div className="text-center mt-3">
              <span className="text-muted">Already have an account? </span>
              <Link to="../login" className="text-primary">
                Sign in
              </Link>
            </div>
          </CardBody>
        </Card>

        <style>
          {`
            .modern-login-card {
              background: white;
              border-radius: 16px;
              box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
            }

            .brand-text {
              color: #32325d;
              font-size: 1.5rem;
              font-weight: 700;
              letter-spacing: 1px;
              text-decoration: none;
              transition: opacity 0.2s ease;
              padding-left: 15px;
            }

            .subtitle-text {
              font-size: 1rem;
              color: #8898aa;
              margin-bottom: 0;
              letter-spacing: 0.3px;
            }

            .section-title {
              color: #32325d;
              font-size: 1rem;
              font-weight: 600;
              text-transform: uppercase;
              letter-spacing: 0.5px;
            }

            .form-section {
              padding: 1.5rem;
              background: #f7fafc;
              border-radius: 12px;
              margin-bottom: 2rem;
            }

            .form-control-label {
              font-weight: 600;
              font-size: 0.875rem;
              color: #525f7f;
              margin-bottom: 0.5rem;
            }

            .input-group-merge {
              box-shadow: 0 3px 6px rgba(50, 50, 93, 0.1);
              border-radius: 8px;
              transition: box-shadow 0.2s ease;
              background: white;
            }

            .input-group-merge:focus-within {
              box-shadow: 0 4px 12px rgba(50, 50, 93, 0.2);
            }

            .input-group-text {
              border: none !important;
              background: transparent !important;
              color: #8898aa;
              padding-right: 16px;
            }

            .form-control {
              border: none !important;
              font-size: 1rem;
              padding-left: 0;
            }

            .requirements-list {
              list-style: none;
              padding-left: 0;
              margin-top: 0.5rem;
            }

            .requirements-list li {
              font-size: 0.875rem;
              margin-bottom: 0.25rem;
              display: flex;
              align-items: center;
            }

            .requirements-list li i {
              width: 16px;
              margin-right: 8px;
            }

            .price-card {
              background: linear-gradient(135deg, #5e72e4 0%, #825ee4 100%);
              border-radius: 16px;
              padding: 2rem;
              color: white;
              position: relative;
              overflow: hidden;
              box-shadow: 0 15px 35px rgba(94, 114, 228, 0.2);
              transition: transform 0.2s ease;
            }

            .price-badge {
              background: rgba(255, 255, 255, 0.1);
              padding: 0.5rem 1rem;
              border-radius: 20px;
              font-size: 0.875rem;
              font-weight: 600;
              letter-spacing: 1px;
              margin-bottom: 1.5rem;
              display: inline-block;
            }

            .price-amount {
              font-size: 3rem;
              font-weight: 700;
              line-height: 1;
              margin-bottom: 1rem;
            }

            .price-period {
              font-size: 1.125rem;
              font-weight: 400;
              opacity: 0.8;
              margin-left: 0.5rem;
            }

            .price-features {
              margin-top: 1.5rem;
            }

            .price-features ul {
              list-style: none;
              padding: 0;
              margin: 0;
            }

            .price-features li {
              margin-bottom: 0.75rem;
              font-size: 1rem;
              display: flex;
              align-items: center;
            }

            .price-features li i {
              margin-right: 0.75rem;
              font-size: 1.125rem;
            }

            .payment-form-group {
              margin-top: 2rem;
            }

            .card-element-wrapper {
              position: relative;
            }

            .card-element-container {
              background: white;
              padding: 1.25rem;
              border-radius: 12px;
              box-shadow: 0 3px 6px rgba(50, 50, 93, 0.1);
              transition: box-shadow 0.2s ease;
              border: 2px solid transparent;
            }

            .card-element-container:hover {
              box-shadow: 0 6px 12px rgba(50, 50, 93, 0.15);
            }

            .card-element-container.StripeElement--focus {
              border-color: #5e72e4;
              box-shadow: 0 6px 12px rgba(94, 114, 228, 0.15);
            }

            .card-brands {
              display: flex;
              justify-content: flex-end;
              gap: 1rem;
              margin-top: 1rem;
            }

            .card-brands i {
              font-size: 1.5rem;
              color: #8898aa;
              transition: color 0.2s ease;
            }

            .card-brands i:hover {
              color: #525f7f;
            }

            .payment-security {
              display: flex;
              align-items: center;
              justify-content: center;
              color: #8898aa;
            }

            .payment-security i {
              color: #2dce89;
            }

            .login-button {
              padding: 0.75rem 1.5rem;
              font-size: 1rem;
              border-radius: 8px;
              font-weight: 600;
              transition: all 0.2s ease;
            }

            .login-button:hover:not(:disabled) {
              transform: translateY(-1px);
              box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
            }

            .custom-control-label {
              font-size: 0.875rem;
              color: #525f7f;
            }

            .custom-control-label a {
              color: #5e72e4;
              text-decoration: none;
              font-weight: 500;
            }

            .custom-control-label a:hover {
              color: #324cdd;
              text-decoration: none;
            }

            @media (max-width: 768px) {
              .modern-login-card {
                margin: 1rem;
              }

              .brand-text {
                font-size: 1.75rem;
              }

              .price-amount {
                font-size: 2.5rem;
              }

              .form-section {
                padding: 1.5rem;
              }

              .price-card {
                padding: 1.5rem;
              }

              .price-amount {
                font-size: 2.5rem;
              }

              .price-features li {
                font-size: 0.875rem;
              }

              .card-brands {
                gap: 0.75rem;
              }

              .card-brands i {
                font-size: 1.25rem;
              }


            }
          `}
        </style>
      </Col>
    </>
  );
};

export default Register;
