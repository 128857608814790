import React from "react";
import { NavItem, NavLink, Nav, Container, Row, Col } from "reactstrap";

const navLinks = [
  { href: "https://betmagic.app/#howitworks", text: "How it works" },
  { href: "https://betmagic.app/articles/", text: "Articles" },
  { href: "https://betmagic.app/help/", text: "Help Center" },
  { href: "https://betmagic.app/privacy-policy/", text: "Privacy & Terms" },
];

const AuthFooter = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="auth-footer">
      <Container>
        <Row className="align-items-center">
          <Col xs={12} md={4} className="footer-left">
            <span className="copyright">
              © {currentYear}{" "}
              <a href="https://betmagic.app/" target="_blank" rel="noopener noreferrer" className="brand-link">
                Bet Magic
              </a>
            </span>
          </Col>
          <Col xs={12} md={8} className="footer-right">
            <Nav className="nav-footer">
              {navLinks.map((link, index) => (
                <NavItem key={index}>
                  <NavLink href={link.href} target="_blank" rel="noopener noreferrer">
                    {link.text}
                  </NavLink>
                </NavItem>
              ))}
              <NavItem className="social-item">
                <span className="follow-text">Follow us on:</span>
                <a href="https://x.com/betmagic_app" target="_blank" rel="noopener noreferrer" className="social-link" aria-label="Follow us on X">
                  <i className="fab fa-x-twitter"></i>
                </a>
                <a
                  href="https://www.facebook.com/betmagic_app"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="social-link ms-2"
                  aria-label="Follow us on Facebook"
                >
                  <i className="fab fa-facebook"></i>
                </a>
                <a
                  href="https://www.instagram.com/betmagic_app"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="social-link ms-2"
                  aria-label="Follow us on Instagram"
                >
                  <i className="fab fa-instagram"></i>
                </a>
                <a
                  href="https://www.youtube.com/@betmagic_app"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="social-link ms-2"
                  aria-label="Subscribe on YouTube"
                >
                  <i className="fab fa-youtube"></i>
                </a>
              </NavItem>
            </Nav>
          </Col>
        </Row>
      </Container>

      <style>
        {`
          .auth-footer {
            padding: 1.5rem;
            background: rgba(23, 32, 46, 0.02);
            border-top: 1px solid rgba(94, 114, 228, 0.08);
          }

          .footer-left {
            display: flex;
            align-items: center;
          }

          .footer-right {
            display: flex;
            align-items: center;
            justify-content: flex-end;
          }

          .copyright {
            color: #8898aa;
            font-size: 0.875rem;
            white-space: nowrap;
          }

          .brand-link {
            color: #5e72e4;
            text-decoration: none;
            transition: color 0.2s ease;
          }

          .brand-link:hover {
            color: #324cdd;
            text-decoration: none;
          }

          .nav-footer {
            display: flex;
            align-items: center;
            gap: 2rem;
            margin: 0;
          }

          .nav-footer .nav-link {
            padding: 0;
            font-size: 0.875rem;
            color: #525f7f;
            font-weight: 500;
            transition: all 0.2s ease;
            white-space: nowrap;
          }

          .nav-footer .nav-link:hover {
            color: #5e72e4;
            transform: translateY(-1px);
          }

          .social-item {
            display: flex;
            align-items: center;
            margin-left: 0.5rem;
          }

          .follow-text {
            color: #8898aa;
            font-size: 0.875rem;
            margin-right: 0.75rem;
            white-space: nowrap;
          }

          .social-link {
            width: 28px;
            height: 28px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 6px;
            background: rgba(94, 114, 228, 0.1);
            color: #5e72e4;
            transition: all 0.2s ease;
          }

          .social-link:hover {
            background: #5e72e4;
            color: white;
            transform: translateY(-1px);
          }

          .ms-2 {
            margin-left: 0.5rem;
          }

          @media (max-width: 768px) {
            .auth-footer {
              padding: 1rem;
            }

            .footer-left {
              justify-content: center;
              margin-bottom: 1rem;
            }

            .footer-right {
              justify-content: center;
            }

            .nav-footer {
              flex-direction: column;
              gap: 1rem;
              align-items: center;
            }

            .social-item {
              margin-left: 0;
              margin-top: 0.5rem;
            }
          }

          @media (max-width: 576px) {
            .nav-footer .nav-link {
              font-size: 0.8125rem;
            }
          }
        `}
      </style>
    </footer>
  );
};

export default AuthFooter;
