import { createContext, useContext, useEffect, useState } from "react";
import { firebaseAuth } from "../firebase/config";
import { doc, onSnapshot } from "firebase/firestore";
import { firebaseDb } from "../firebase/config";
import { onAuthStateChanged } from "firebase/auth";

export const UserContext = createContext(undefined);

export function useUser() {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error("useUser must be used within a UserProvider");
  }
  return context;
}

export function UserProvider({ children }) {
  const [state, setState] = useState({
    user: null,
    isLoading: true,
    error: null,
  });

  useEffect(() => {
    // First, ensure auth is ready
    firebaseAuth.authStateReady().then(() => {
      // Set up auth state listener
      const unsubscribeAuth = onAuthStateChanged(
        firebaseAuth,
        async (firebaseUser) => {
          if (!firebaseUser) {
            setState((prev) => ({
              ...prev,
              user: null,
              isLoading: false,
            }));
            return;
          }

          // Set up Firestore listener for user data
          const userRef = doc(firebaseDb, "users", firebaseUser.uid);
          const unsubscribeFirestore = onSnapshot(
            userRef,
            (doc) => {
              if (doc.exists()) {
                const userData = doc.data();
                setState((prev) => ({
                  ...prev,
                  user: {
                    id: firebaseUser.uid,
                    ...firebaseUser,
                    ...userData,
                  },
                  isLoading: false,
                }));
              } else {
                setState((prev) => ({
                  ...prev,
                  isLoading: false,
                  error: new Error("User document not found"),
                }));
              }
            },
            (error) => {
              setState((prev) => ({
                ...prev,
                isLoading: false,
                error,
              }));
            }
          );

          // Cleanup Firestore listener when auth state changes
          return () => unsubscribeFirestore();
        },
        (error) => {
          setState((prev) => ({
            ...prev,
            isLoading: false,
            error,
          }));
        }
      );

      // Cleanup auth listener on unmount
      return () => unsubscribeAuth();
    });
  }, []);

  const value = {
    user: state.user,
    isLoading: state.isLoading,
    error: state.error,
  };

  return <UserContext value={value}>{children}</UserContext>;
}
