import React from "react";
import { Container, Row, Col } from "reactstrap";
import GamesCountCard from "../Cards/GamesCountCard";

const GamesListHeader = ({ league, currentGames }) => {
  return (
    <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
      <Container fluid>
        <div className="header-body">
          <Row>
            <Col lg="6" xl="3">
              <GamesCountCard league={league} currentGames={currentGames} />
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default GamesListHeader;
