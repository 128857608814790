import { Button, Card, CardBody, FormGroup, Form, Input, InputGroupText, InputGroup, Col } from "reactstrap";
import { useNavigate, Link } from "react-router";
import { firebaseAuth, analytics } from "../../firebase/config.js";
import { sendPasswordResetEmail } from "firebase/auth";
import { useState, useEffect } from "react";
import AlertPopup from "./AlertPopup.js";
import { isValidEmail } from "../../utils.js";
import { logEvent } from "firebase/analytics";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [alertInfo, setAlertInfo] = useState({ visible: false, type: "danger", message: "" });
  const [formErrors, setFormErrors] = useState({
    email: "",
  });

  useEffect(() => {
    // Track page view when component mounts
    logEvent(analytics, "page_view", {
      page_title: "Forgot Password Page",
      page_location: window.location.href,
      page_path: window.location.pathname,
    });
  }, []);

  const validateForm = () => {
    let isValid = true;
    const errors = { email: "" };

    if (!email) {
      errors.email = "Email is required";
      isValid = false;
    } else if (!isValidEmail(email)) {
      errors.email = "Please enter a valid email address";
      isValid = false;
    }

    setFormErrors(errors);

    // Track validation failure
    if (!isValid) {
      logEvent(analytics, "forgot_password_validation_failed", {
        email_error: !!errors.email,
      });
    }

    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setIsLoading(true);
    try {
      // Track reset attempt
      logEvent(analytics, "password_reset_attempt", {
        email_provided: true,
      });

      await sendPasswordResetEmail(firebaseAuth, email);

      // Track successful reset email sent
      logEvent(analytics, "password_reset_email_sent", {
        success: true,
      });

      setAlertInfo({
        visible: true,
        type: "success",
        message: "Password reset email has been sent. Please check your inbox.",
      });

      // Delay navigation to show success message
      setTimeout(() => navigate("/auth/login"), 3000);
    } catch (err) {
      // Track reset error
      logEvent(analytics, "password_reset_error", {
        error_code: err.code,
        error_message: err.message,
      });

      const errorMessages = {
        "auth/user-not-found": "No user found with this email",
        "auth/invalid-email": "Invalid email format",
        "auth/too-many-requests": "Too many requests. Please try again later.",
      };

      setAlertInfo({
        visible: true,
        type: "danger",
        message: errorMessages[err.code] || "Could not reset password. Please try again.",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <title>Reset Password - Bet Magic</title>
      <meta name="description" content="Reset your Bet Magic account password securely. Follow the simple steps to regain access to your account." />
      <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
      <meta name="theme-color" content="#5e72e4" />
      <meta name="application-name" content="Bet Magic" />
      <meta property="og:title" content="Reset Password - Bet Magic" />
      <meta property="og:description" content="Reset your Bet Magic account password securely." />
      <meta property="og:type" content="website" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@betmagic_app" />
      <meta name="twitter:creator" content="@betmagic_app" />
      <meta name="twitter:image" content="https://launch.betmagic.app/static/media/profile-cover.b363fe516aaeb51d46cb.jpg" />
      <meta name="twitter:image:alt" content="Bet Magic Logo" />
      <meta name="twitter:title" content="Reset Password - Bet Magic" />
      <meta name="twitter:description" content="Reset your Bet Magic account password securely." />
      <link rel="canonical" href={window.location.href} />

      <AlertPopup
        showAlert={alertInfo.visible}
        alertType={alertInfo.type}
        onAlertClick={() => setAlertInfo({ ...alertInfo, visible: false })}
        message={alertInfo.message}
        role="alert"
        aria-live="polite"
      />
      <Col lg="5" md="7">
        <Card className="modern-login-card border-0">
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center mb-5">
              <h1 className="brand-text mb-3">BET MAGIC</h1>
              <p className="text-muted subtitle-text">Enter your email to reset your password</p>
            </div>
            <Form role="form" onSubmit={handleSubmit} noValidate>
              <FormGroup className="mb-4">
                <label htmlFor="email" className="form-control-label">
                  Email Address
                </label>
                <InputGroup className="input-group-merge">
                  <InputGroupText>
                    <i className="ni ni-email-83" aria-hidden="true" />
                  </InputGroupText>
                  <Input
                    id="email"
                    className="form-control-alternative"
                    placeholder="name@example.com"
                    type="email"
                    autoComplete="email"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    aria-label="Email Address"
                    aria-describedby="email-error"
                    aria-invalid={!!formErrors.email}
                    required
                  />
                </InputGroup>
                {formErrors.email && (
                  <div className="text-danger mt-2" id="email-error" role="alert">
                    <small>{formErrors.email}</small>
                  </div>
                )}
              </FormGroup>

              <div className="text-center">
                <Button
                  className="my-4 w-100 login-button"
                  color="primary"
                  type="submit"
                  disabled={isLoading}
                  aria-label={isLoading ? "Sending reset email..." : "Reset Password"}
                >
                  {isLoading ? (
                    <>
                      <i className="fas fa-spinner fa-spin mr-2" />
                      Sending...
                    </>
                  ) : (
                    "Reset Password"
                  )}
                </Button>
              </div>
            </Form>
            <div className="text-center mt-3">
              <Link to="../login" className="text-primary back-to-login">
                <i className="fas fa-arrow-left mr-2"></i>
                Back to login
              </Link>
            </div>
          </CardBody>
        </Card>

        <style>
          {`
            .modern-login-card {
              background: white;
              border-radius: 16px;
              box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
            }

            .brand-text {
              color: #32325d;
              font-size: 1.5rem;
              font-weight: 700;
              letter-spacing: 1px;
              text-decoration: none;
              transition: opacity 0.2s ease;
              padding-left: 15px;
            }

            .subtitle-text {
              font-size: 1rem;
              color: #8898aa;
              margin-bottom: 0;
              letter-spacing: 0.3px;
            }

            .form-control-label {
              font-weight: 600;
              font-size: 0.875rem;
              color: #525f7f;
              margin-bottom: 0.5rem;
            }

            .input-group-merge {
              box-shadow: 0 3px 6px rgba(50, 50, 93, 0.1);
              border-radius: 8px;
              transition: box-shadow 0.2s ease;
            }

            .input-group-merge:focus-within {
              box-shadow: 0 4px 12px rgba(50, 50, 93, 0.2);
            }

            .input-group-text {
              border: none !important;
              background: transparent !important;
              color: #8898aa;
              padding-right: 16px;
            }

            .form-control {
              border: none !important;
              font-size: 1rem;
              padding-left: 0;
            }

            .login-button {
              padding: 0.75rem 1.5rem;
              font-size: 1rem;
              border-radius: 8px;
              font-weight: 600;
              transition: all 0.2s ease;
            }

            .login-button:hover:not(:disabled) {
              transform: translateY(-1px);
              box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
            }

            .back-to-login {
              font-size: 0.875rem;
              font-weight: 500;
              text-decoration: none;
              transition: color 0.2s ease;
              display: inline-flex;
              align-items: center;
            }

            .back-to-login:hover {
              color: #2643e9;
              text-decoration: none;
            }

            .back-to-login i {
              font-size: 0.75rem;
            }

            @media (max-width: 768px) {
              .modern-login-card {
                margin: 1rem;
              }

              .brand-text {
                font-size: 1.75rem;
              }
            }
          `}
        </style>
      </Col>
    </>
  );
};

export default ForgotPassword;
