import React from "react";
import { Card, CardBody, CardTitle, Row, Col } from "reactstrap";
import "./ModernCard.css";

const GamesCountCard = ({ league, currentGames }) => (
  <Card className="modern-card">
    <CardBody>
      <Row className="align-items-center">
        <Col>
          <CardTitle tag="h6" className="text-uppercase text-muted mb-2 font-weight-bold">
            {`${league} Games Today`}
          </CardTitle>
          <span className="card-value">{currentGames}</span>
        </Col>
        <Col xs="auto">
          <div className="icon-container">
            <i className="fas fa-calendar-day" />
          </div>
        </Col>
      </Row>
    </CardBody>
  </Card>
);

export default GamesCountCard;
