import React from "react";
import { Navbar, Container } from "reactstrap";

const DashboardNavbar = () => {
  return (
    <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
      <Container fluid className="justify-content-end" />
    </Navbar>
  );
};

export default DashboardNavbar;
