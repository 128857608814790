const timezone = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone || "America/Chicago";

const isValidEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

const dateOptions = {
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
  timeZone: timezone,
};

const timeOptions = {
  hour: "2-digit",
  minute: "2-digit",
  hour12: true,
  timeZone: timezone,
};

const formatDate = (dateString) => new Intl.DateTimeFormat("default", dateOptions).format(new Date(dateString));
const formatTime = (dateString) => new Intl.DateTimeFormat("default", timeOptions).format(new Date(dateString));

const getStatusBadgeColor = (status) => {
  switch (status) {
    case "Ongoing":
      return "bg-success";
    case "Finished":
      return "bg-danger";
    default:
      return "bg-warning";
  }
};

const getCellStyle = (liveTotal, threshold, isUnder) => {
  if (isUnder) {
    return liveTotal > threshold ? "text-success" : "";
  } else {
    return liveTotal < threshold ? "text-danger" : "";
  }
};

const getGameTotal = (game, bookmaker) => {
  let selectedBookmaker = game.bookmakers.find((b) => b.key === bookmaker);
  let totalsMarket = selectedBookmaker.markets.find((m) => m.key === "totals");
  return totalsMarket.outcomes[0].point;
};

const getGameSpread = (game, bookmaker) => {
  let selectedBookmaker = game.bookmakers.find((b) => b.key === bookmaker);
  let totalsMarket = selectedBookmaker.markets.find((m) => m.key === "spreads");
  return totalsMarket.outcomes;
};

const getLineValue = (sportKey, originalTotal, isUnder) => {
  let adjustment;

  switch (sportKey) {
    case "americanfootball_nfl":
      adjustment = originalTotal < 45 ? 13.5 : 14.5;
      break;
    case "basketball_nba":
      adjustment = originalTotal < 200 ? 14 : 15;
      break;
    case "americanfootball_ncaaf":
      adjustment = originalTotal < 45 ? 17.5 : originalTotal < 55 ? 18.5 : originalTotal < 65 ? 20 : 22.5;
      break;
    case "baseball_mlb":
      adjustment = originalTotal < 9.5 ? 4 : 4.5;
      break;
    default:
      throw new Error(`Unsupported sport type: ${sportKey}`);
  }

  return isUnder ? originalTotal + adjustment : originalTotal - adjustment;
};

const getGameStatus = (game) => {
  if (!game) return { color: "warning", text: "UPCOMING" };
  return (
    {
      Ongoing: { color: "success", text: "LIVE" },
      Finished: { color: "danger", text: "FINAL" },
      "Not Started": { color: "warning", text: "UPCOMING" },
    }[game.status] || { color: "warning", text: game.status || "UPCOMING" }
  );
};

const calculateType = (latestTotal, originalTotal) => {
  if (!latestTotal || !originalTotal) return "EQUAL";
  if (latestTotal === originalTotal) return "EQUAL";
  if (latestTotal > originalTotal) return "OVER";
  return "UNDER";
};

const calculatePercentage = (latestTotal, originalTotal) => {
  if (!latestTotal || !originalTotal) return 0;
  const average = (latestTotal + originalTotal) / 2;
  if (average === 0) return 0;
  return 100 * Math.abs((latestTotal - originalTotal) / average);
};

export {
  formatDate,
  formatTime,
  getStatusBadgeColor,
  getCellStyle,
  getGameTotal,
  getGameSpread,
  getLineValue,
  isValidEmail,
  getGameStatus,
  calculateType,
  calculatePercentage,
};
