import React from "react";
import { Container, Row, Col } from "reactstrap";
import OriginalTotalCard from "../Cards/OriginalTotalCard";
import OriginalSpreadCard from "../Cards/OriginalSpreadCard";
import GameScoreCard from "../Cards/GameScoreCard";

const GameHeader = ({ total = "N/A", type, percentage, spread, scores }) => {
  return (
    <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
      <Container fluid>
        <div className="header-body">
          <Row>
            <Col lg="6" xl="3" className="mb-4 mb-xl-0">
              <OriginalTotalCard total={total} statsType={type} percentage={percentage} />
            </Col>
            <Col lg="6" xl="3" className="mb-4 mb-xl-0">
              <OriginalSpreadCard spread={spread} />
            </Col>
            <Col lg="6" xl="3" className="mb-4 mb-xl-0">
              <GameScoreCard scores={scores} />
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default GameHeader;
