import "chart.js/auto";

import { Card, CardHeader, CardBody, Container, Row, Col } from "reactstrap";

import Header from "../components/Headers/Header.js";

const WelcomePage = () => {
  return (
    <>
      {/* Document Metadata */}
      <title>Dashboard - Bet Magic</title>
      <meta
        name="description"
        content="Access your Bet Magic dashboard for live sports betting insights. Get real-time updates and make informed betting decisions."
      />
      <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
      <meta name="theme-color" content="#5e72e4" />
      <meta name="application-name" content="Bet Magic" />
      <meta property="og:title" content="Dashboard - Bet Magic" />
      <meta property="og:description" content="Access your Bet Magic dashboard for live sports betting insights and real-time updates." />
      <meta property="og:type" content="website" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@betmagic_app" />
      <meta name="twitter:creator" content="@betmagic_app" />
      <meta name="twitter:image" content="https://launch.betmagic.app/static/media/profile-cover.b363fe516aaeb51d46cb.jpg" />
      <meta name="twitter:image:alt" content="Bet Magic Logo" />
      <meta name="twitter:title" content="Dashboard - Bet Magic" />
      <link rel="canonical" href={window.location.href} />

      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <Col className="mb-5 mb-xl-0">
            <Card className="shadow">
              <CardHeader className="bg-gradient-primary">
                <Row className="align-items-center">
                  <div className="col">
                    <h1 className="text-white display-3 mb-0">Welcome to Bet Magic!</h1>
                    <p className="text-white-50 mt-3 mb-0">
                      Your intelligent companion for live sports betting. Follow our real-time insights to make informed betting decisions.
                    </p>
                  </div>
                </Row>
              </CardHeader>

              <CardBody className="pt-5">
                <div className="getting-started-section mb-5">
                  <div className="section-icon mb-4">
                    <i className="fas fa-rocket text-primary" />
                  </div>
                  <h2 className="heading-title mb-4">Getting Started</h2>
                  <div className="step-grid">
                    <div className="step-item">
                      <div className="step-number">1</div>
                      <h4>Choose Your Sport</h4>
                      <p>Select your favorite sport from the menu on the left to view available games.</p>
                    </div>
                    <div className="step-item">
                      <div className="step-number">2</div>
                      <h4>Track Live Games</h4>
                      <p>Monitor game volatility and betting opportunities in real-time.</p>
                    </div>
                    <div className="step-item">
                      <div className="step-number">3</div>
                      <h4>Stay Ready</h4>
                      <p>Keep your sportsbook open and enable notifications for timely alerts.</p>
                    </div>
                  </div>
                </div>

                <div className="best-practices-section">
                  <div className="section-icon mb-4">
                    <i className="fas fa-shield-alt text-success" />
                  </div>
                  <h2 className="heading-title mb-4">Betting Best Practices</h2>
                  <div className="practices-container">
                    <div className="practice-card">
                      <div className="practice-icon">
                        <i className="fas fa-brain text-primary" />
                      </div>
                      <h4>Bet with Logic, Not Emotion</h4>
                      <p>Our algorithm works on odds and data, not feelings. Maintain consistent bet sizes regardless of your predictions.</p>
                    </div>

                    <div className="practice-card">
                      <div className="practice-icon">
                        <i className="fas fa-balance-scale text-warning" />
                      </div>
                      <h4>Use Consistent Units</h4>
                      <p>Bet the same amount each game. We recommend 1 unit per bet (e.g., if your unit is $25, stick to $25 per game).</p>
                    </div>

                    <div className="practice-card">
                      <div className="practice-icon">
                        <i className="fas fa-hand-holding-heart text-danger" />
                      </div>
                      <h4>Bet Responsibly</h4>
                      <p>Treat betting as entertainment, not income. Never bet more than you can afford to lose.</p>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>

      <style>
        {`
          .section-icon {
            font-size: 2.5rem;
            text-align: center;
          }

          .heading-title {
            font-size: 1.75rem;
            font-weight: 600;
            text-align: center;
            color: #32325d;
            letter-spacing: 0.05em;
          }

          .step-grid {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
            gap: 2rem;
            margin-top: 2rem;
          }

          .step-item {
            text-align: center;
            padding: 1.5rem;
            background: #f7fafc;
            border-radius: 1rem;
            position: relative;
          }

          .step-number {
            width: 2.5rem;
            height: 2.5rem;
            background: #5e72e4;
            color: white;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: bold;
            margin: -2.5rem auto 1rem;
            border: 3px solid white;
          }

          .step-item h4 {
            color: #32325d;
            margin-bottom: 1rem;
            font-weight: 600;
          }

          .step-item p {
            color: #525f7f;
            margin-bottom: 0;
          }

          .practices-container {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
            gap: 2rem;
            margin-top: 2rem;
          }

          .practice-card {
            padding: 2rem;
            background: #f7fafc;
            border-radius: 1rem;
            text-align: center;
            transition: transform 0.2s ease;
          }

          .practice-card:hover {
            transform: translateY(-5px);
          }

          .practice-icon {
            font-size: 2rem;
            margin-bottom: 1.5rem;
          }

          .practice-card h4 {
            color: #32325d;
            margin-bottom: 1rem;
            font-weight: 600;
          }

          .practice-card p {
            color: #525f7f;
            margin-bottom: 0;
          }

          @media (max-width: 768px) {
            .step-grid, .practices-container {
              grid-template-columns: 1fr;
              gap: 1.5rem;
            }

            .practice-card {
              padding: 1.5rem;
            }

            .heading-title {
              font-size: 1.5rem;
            }
          }
        `}
      </style>
    </>
  );
};

export default WelcomePage;
