import React from "react";
import { Card, CardBody } from "reactstrap";

const NoGamesRow = ({ rowText, isMobile = false }) => {
  const NoGamesContent = () => (
    <div className="no-games-content">
      <div className="icon-wrapper mb-3">
        <i className="fas fa-calendar-xmark" />
      </div>
      <h3 className="mb-2">{rowText}</h3>
      <p className="text-muted mb-0">Check back later for updates</p>
    </div>
  );

  if (isMobile) {
    return (
      <Card className="no-games-card">
        <CardBody className="text-center py-5">
          <NoGamesContent />
        </CardBody>
      </Card>
    );
  }

  return (
    <tr className="no-games-row">
      <td colSpan="8" className="text-center py-5">
        <NoGamesContent />
      </td>
    </tr>
  );
};

export default NoGamesRow;
