import { useEffect } from "react";
import { useLocation } from "react-router";

export const useScrollToTop = () => {
  const location = useLocation();

  useEffect(() => {
    const scrollToTop = () => {
      // Use modern smooth scrolling
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    };

    // Start transition to scroll
    scrollToTop();

    // Cleanup function
    return () => {
      // Cancel any pending scroll animations
      window.scrollTo({
        top: window.scrollY,
        left: window.scrollX,
        behavior: "auto",
      });
    };
  }, [location.pathname]); // Only re-run when the path changes
};
