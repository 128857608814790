import { getGameTotal, getLineValue, formatTime } from "../utils";

var colors = {
  gray: {
    100: "#f6f9fc",
    200: "#e9ecef",
    300: "#dee2e6",
    400: "#ced4da",
    500: "#adb5bd",
    600: "#8898aa",
    700: "#525f7f",
    800: "#32325d",
    900: "#212529",
  },
  theme: {
    default: "#172b4d",
    primary: "#6340fb",
    secondary: "#f4f5f7",
    info: "#11cdef",
    success: "#2dce89",
    danger: "#f5365c",
    warning: "#fb6340",
  },
  black: "#12263F",
  white: "#FFFFFF",
  transparent: "transparent",
};

const options = {
  responsive: true,
  maintainAspectRatio: false,
  layout: {
    padding: 0,
  },
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: true,
      mode: "index",
      intersect: false,
      position: "nearest",
      events: ["mousemove", "mouseout", "touchstart", "touchmove", "touchend"],
      animation: {
        duration: 150,
      },
    },
  },
  hover: {
    mode: "index",
    intersect: false,
    cursor: "pointer",
  },
  onHover: (event, elements) => {
    const target = event.native?.target || event.target;
    if (target) {
      target.style.cursor = elements?.length ? "pointer" : "default";
    }
  },
  elements: {
    point: {
      radius: 0,
      backgroundColor: colors.theme["primary"],
    },
    line: {
      tension: 0.4,
      borderWidth: 4,
      borderColor: colors.theme["primary"],
      backgroundColor: colors.transparent,
      borderCapStyle: "rounded",
    },
  },
  scales: {
    y: {
      grid: {
        display: false,
        drawBorder: false,
      },
      ticks: {
        beginAtZero: false,
        padding: 10,
        color: colors.gray[600],
        font: {
          family: "Open Sans",
          size: 13,
        },
      },
    },
    x: {
      grid: {
        display: false,
        drawBorder: false,
      },
      ticks: {
        beginAtZero: true,
        padding: 20,
        color: colors.gray[600],
        font: {
          family: "Open Sans",
          size: 13,
        },
      },
    },
  },
};

// Helper function to format timestamps
const formatChartTime = (timestamps) => {
  if (!timestamps?.length) return [];

  return timestamps.map((timestamp) => formatTime(timestamp));
};

const formatSpreadChartData = (spreads) => {
  if (!spreads?.length) return null;

  const timestamps = spreads.map((spread) => spread.createdAt);
  const labels = formatChartTime(timestamps);
  const homeData = [];
  const awayData = [];

  spreads.forEach((spread) => {
    homeData.push(spread.outcomes[0].point);
    awayData.push(spread.outcomes[1].point);
  });

  return {
    labels,
    datasets: [
      {
        label: spreads[0].outcomes[0].name,
        data: homeData,
        borderColor: "#40fb63",
        backgroundColor: "#40fb63",
        fill: false,
        tension: 0.4,
      },
      {
        label: spreads[0].outcomes[1].name,
        data: awayData,
        borderColor: "#5e72e4",
        backgroundColor: "#5e72e4",
        fill: false,
        tension: 0.4,
      },
    ],
  };
};

const formatTotalChartData = (totals, game) => {
  if (!totals?.length) return null;

  const timestamps = totals.map((total) => total.createdAt);
  const labels = formatChartTime(timestamps);
  const liveTotals = [];

  totals.forEach((total) => {
    liveTotals.push(total.outcomes[0].point);
  });

  const predictedTotal = getGameTotal(game, "draftkings") || liveTotals[0];
  const sportType = game.sport_key;

  const datasets = [
    {
      label: "Live Total",
      data: liveTotals,
      borderColor: "#5e72e4",
      backgroundColor: "#5e72e4",
      fill: false,
      tension: 0.4,
    },
    {
      label: "Predicted Total",
      data: new Array(labels.length).fill(predictedTotal),
      borderColor: "#fb6340",
      backgroundColor: "#fb6340",
      fill: false,
      tension: 0.4,
    },
  ];

  if (sportType && predictedTotal) {
    datasets.push(
      {
        label: "Bet Under",
        data: new Array(labels.length).fill(getLineValue(sportType, predictedTotal, true)),
        borderColor: "#40fb63",
        backgroundColor: "#40fb63",
        fill: false,
        tension: 0.4,
      },
      {
        label: "Bet Over",
        data: new Array(labels.length).fill(getLineValue(sportType, predictedTotal, false)),
        borderColor: "#40fb63",
        backgroundColor: "#40fb63",
        fill: false,
        tension: 0.4,
      }
    );
  }

  return {
    labels,
    datasets,
  };
};

export { options, formatSpreadChartData, formatTotalChartData };
