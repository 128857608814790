import { Route, Routes, Navigate } from "react-router";
import { Container, Row } from "reactstrap";

// Components
import AuthNavbar from "../components/Navbars/AuthNavbar.js";
import AuthFooter from "../components/Footers/AuthFooter.js";
import Login from "../views/examples/Login.js";
import Register from "../views/examples/Register.js";
import ForgotPassword from "../views/examples/ForgotPassword.js";

const Auth = () => {
  return (
    <>
      <div className="main-content">
        <div className="animated-background">
          <div className="light-effect"></div>
          <div className="particles">
            {[...Array(30)].map((_, index) => (
              <div key={index} className={`particle particle-${index + 1}`} />
            ))}
          </div>
          <div className="glow-effects">
            <div className="glow glow-1"></div>
            <div className="glow glow-2"></div>
            <div className="glow glow-3"></div>
          </div>
        </div>
        <AuthNavbar />
        <div className="header-wrapper">
          <Container className="header-container">
            <div className="header-content text-center">
              <div className="brand-logo mb-4">
                <h1 className="text-gradient">BET MAGIC</h1>
              </div>
              <h2 className="welcome-text text-white mb-3">Welcome to the Future of Sports Betting</h2>
              <p className="lead text-white-80">Advanced Analytics • Discord Community • Real-time Insights</p>
            </div>
          </Container>
        </div>
        <Container className="mt--8 pb-5 position-relative">
          <Row className="justify-content-center">
            <Routes>
              <Route path="login" element={<Login />} replace />
              <Route path="register" element={<Register />} replace />
              <Route path="forgot-password" element={<ForgotPassword />} replace />
              <Route path="*" element={<Navigate to="/auth/login" replace />} />
            </Routes>
          </Row>
        </Container>
        <AuthFooter />
      </div>

      <style>
        {`
          .main-content {
            min-height: 100vh;
            position: relative;
            overflow: hidden;
          }

          .animated-background {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 0;
            background: linear-gradient(135deg, #1a1f4c 0%, #172b4d 100%);
            overflow: hidden;
          }

          .light-effect {
            position: absolute;
            width: 200%;
            height: 200%;
            top: -50%;
            left: -50%;
            background: radial-gradient(circle at center, rgba(255,255,255,0.05) 0%, transparent 50%);
            animation: rotate 45s linear infinite;
          }

          .particles {
            position: absolute;
            width: 100%;
            height: 100%;
            overflow: hidden;
            z-index: 1;
          }

          .particle {
            position: absolute;
            background: rgba(255,255,255,0.1);
            border-radius: 50%;
            pointer-events: none;
          }

          ${[...Array(30)]
            .map(
              (_, i) => `
                .particle-${i + 1} {
                  width: ${4 + Math.random() * 6}px;
                  height: ${4 + Math.random() * 6}px;
                  top: ${Math.random() * 100}vh;
                  left: ${Math.random() * 100}vw;
                  opacity: ${0.3 + Math.random() * 0.5};
                  animation: moveParticle-${i + 1} ${30 + Math.random() * 40}s linear infinite;
                }

                @keyframes moveParticle-${i + 1} {
                  0% {
                    transform: translate(0, 0);
                  }
                  20% {
                    transform: translate(${Math.random() < 0.5 ? "-" : ""}${50 + Math.random() * 100}px, 
                                      ${Math.random() < 0.5 ? "-" : ""}${50 + Math.random() * 100}px);
                  }
                  40% {
                    transform: translate(${Math.random() < 0.5 ? "-" : ""}${50 + Math.random() * 100}px, 
                                      ${Math.random() < 0.5 ? "-" : ""}${50 + Math.random() * 100}px);
                  }
                  60% {
                    transform: translate(${Math.random() < 0.5 ? "-" : ""}${50 + Math.random() * 100}px, 
                                      ${Math.random() < 0.5 ? "-" : ""}${50 + Math.random() * 100}px);
                  }
                  80% {
                    transform: translate(${Math.random() < 0.5 ? "-" : ""}${50 + Math.random() * 100}px, 
                                      ${Math.random() < 0.5 ? "-" : ""}${50 + Math.random() * 100}px);
                  }
                  100% {
                    transform: translate(${Math.random() < 0.5 ? "-" : ""}${50 + Math.random() * 100}px, 
                                      ${Math.random() < 0.5 ? "-" : ""}${50 + Math.random() * 100}px);
                  }
                }`
            )
            .join("\n")}

          .glow-effects {
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: 0;
          }

          .glow {
            position: absolute;
            border-radius: 50%;
            filter: blur(80px);
            opacity: 0.15;
            animation: glow-float 20s ease-in-out infinite;
          }

          .glow-1 {
            top: 20%;
            left: 20%;
            width: 500px;
            height: 500px;
            background: #5e72e4;
            animation-delay: 0s;
          }

          .glow-2 {
            bottom: 20%;
            right: 20%;
            width: 600px;
            height: 600px;
            background: #825ee4;
            animation-delay: -5s;
          }

          .glow-3 {
            top: 50%;
            left: 50%;
            width: 400px;
            height: 400px;
            background: #4756b6;
            animation-delay: -10s;
          }

          @keyframes glow-float {
            0%, 100% {
              transform: translate(0, 0) scale(1);
            }
            33% {
              transform: translate(-50px, 50px) scale(1.1);
            }
            66% {
              transform: translate(50px, -50px) scale(0.9);
            }
          }

          @keyframes rotate {
            from {
              transform: rotate(0deg);
            }
            to {
              transform: rotate(360deg);
            }
          }

          .header-wrapper {
            position: relative;
            padding-top: 8rem;
            padding-bottom: 8rem;
            z-index: 1;
          }

          .header-container {
            position: relative;
            z-index: 1;
          }

          .header-content {
            max-width: 700px;
            margin: 0 auto;
            padding: 2rem 1rem;
          }

          .brand-logo h1 {
            font-size: 3.5rem;
            font-weight: 800;
            letter-spacing: 2px;
            margin: 0;
          }

          .text-gradient {
            background: linear-gradient(45deg, #5e72e4, #825ee4);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
          }

          .welcome-text {
            font-size: 2.5rem;
            font-weight: 600;
            line-height: 1.2;
          }

          .text-white-80 {
            color: rgba(255, 255, 255, 0.8);
          }

          .position-relative {
            position: relative;
            z-index: 2;
          }

          @media (max-width: 768px) {
            .header-wrapper {
              padding-top: 6rem;
              padding-bottom: 6rem;
            }

            .brand-logo h1 {
              font-size: 2.5rem;
            }

            .welcome-text {
              font-size: 2rem;
            }

            .glow {
              opacity: 0.1;
              filter: blur(60px);
            }
          }

          @media (max-width: 480px) {
            .header-wrapper {
              padding-top: 4rem;
              padding-bottom: 4rem;
            }

            .brand-logo h1 {
              font-size: 2rem;
            }

            .welcome-text {
              font-size: 1.5rem;
            }

            .glow {
              opacity: 0.08;
              filter: blur(40px);
            }
          }

          @media (prefers-reduced-motion: reduce) {
            .light-effect,
            .particle,
            .glow {
              animation: none;
            }
          }
        `}
      </style>
    </>
  );
};

export default Auth;
