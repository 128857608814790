import React from "react";
import { Card, CardBody, CardTitle, Row, Col } from "reactstrap";
import "./ModernCard.css";

const ScoreValue = ({ name, score }) => (
  <div className="score-value">
    <span className="team-name">{name || "N/A"}</span>
    <span className="score-number">{score || "0"}</span>
  </div>
);

const GameScoreCard = ({
  scores = [
    { name: "N/A", score: "0" },
    { name: "N/A", score: "0" },
  ],
}) => (
  <Card className="modern-card">
    <CardBody>
      <Row className="align-items-center">
        <Col>
          <CardTitle tag="h6" className="text-uppercase text-muted mb-2 font-weight-bold">
            Current Score
          </CardTitle>
          <div className="score-container">
            <ScoreValue name={scores?.[0]?.name} score={scores?.[0]?.score} />
            <ScoreValue name={scores?.[1]?.name} score={scores?.[1]?.score} />
          </div>
        </Col>
        <Col xs="auto">
          <div className="icon-container">
            <i className="fas fa-trophy" />
          </div>
        </Col>
      </Row>
    </CardBody>
  </Card>
);

export default GameScoreCard;
