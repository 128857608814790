import GameRow from "./GameRow.js";
import NoGamesRow from "./NoGamesRow.js";
import { Table } from "reactstrap";
import { useNavigate } from "react-router";
import { useState, useEffect } from "react";
import GameCard from "./GameCard.js";

const GamesTable = ({ tableClassName = "", theadClassName = "", games, noGameText }) => {
  const navigate = useNavigate();
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  if (isMobileView) {
    return (
      <div className="games-grid">
        {games.length > 0 ? (
          games.map((game) => (
            <div key={game.id} className="game-card-wrapper">
              <GameCard game={game} viewGame={() => navigate(game.id)} />
            </div>
          ))
        ) : (
          <NoGamesRow rowText={noGameText} isMobile={true} />
        )}
      </div>
    );
  }

  return (
    <div className="table-responsive">
      <Table className={`align-items-center table-hover ${tableClassName}`}>
        <thead className={theadClassName}>
          <tr>
            <th scope="col" className="games-column">
              Games
            </th>
            <th scope="col" className="text-center">
              Predicted Total
            </th>
            <th scope="col" className="text-center">
              Live Total
            </th>
            <th scope="col" className="text-center">
              Over
            </th>
            <th scope="col" className="text-center">
              Under
            </th>
            <th scope="col" className="text-center">
              Start Date
            </th>
            <th scope="col" className="text-center">
              Start Time
            </th>
            <th scope="col" className="text-center">
              Game Status
            </th>
          </tr>
        </thead>
        <tbody>
          {games.length > 0 ? (
            games.map((game) => <GameRow key={game.id} game={game} viewGame={() => navigate(game.id)} />)
          ) : (
            <NoGamesRow rowText={noGameText} isMobile={false} />
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default GamesTable;
