import React from "react";
import { createRoot } from "react-dom/client";

import "./assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./assets/scss/argon-dashboard-react.scss";

import App from "./App";
import { BrowserRouter } from "react-router";

import { Elements } from "@stripe/react-stripe-js";
import { stripePromise } from "./stripe/config";
import { UserProvider } from "./context/UserContext";
const container = document.getElementById("root");
const root = createRoot(container, {
  onUncaughtError: (error, errorInfo) => {
    // ... log error report
  },

  onCaughtError: (error, errorInfo) => {
    // ... log error report
  },
});
root.render(
  <BrowserRouter>
    <Elements stripe={stripePromise}>
      <UserProvider>
        <App />
      </UserProvider>
    </Elements>
  </BrowserRouter>
);
