import React, { useState, useEffect } from "react";
import { Card, CardBody, Container, Button, Alert } from "reactstrap";
import { WifiOff, RefreshCw } from "react-feather";
import { useNavigate, useLocation } from "react-router";

const Offline = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [showRetryMessage, setShowRetryMessage] = useState(false);

  useEffect(() => {
    const checkConnection = () => {
      if (navigator.onLine) {
        const previousPath = location.state?.from || "/dashboard/index";
        navigate(previousPath);
      }
    };

    // Check connection every 3 seconds
    const interval = setInterval(checkConnection, 3000);

    return () => clearInterval(interval);
  }, [navigate, location]);

  const handleRetry = () => {
    if (navigator.onLine) {
      const previousPath = location.state?.from || "/dashboard/index";
      navigate(previousPath);
    } else {
      setShowRetryMessage(true);
      setTimeout(() => setShowRetryMessage(false), 3000);
    }
  };

  return (
    <>
      <div className="main-content">
        <div className="animated-background">
          <div className="light-effect"></div>
          <div className="particles">
            {[...Array(30)].map((_, index) => (
              <div key={index} className={`particle particle-${index + 1}`} />
            ))}
          </div>
          <div className="glow-effects">
            <div className="glow glow-1"></div>
            <div className="glow glow-2"></div>
            <div className="glow glow-3"></div>
          </div>
        </div>
        <div className="header-wrapper">
          <Container className="header-container">
            <div className="header-content text-center">
              <div className="brand-logo mb-4">
                <h1 className="text-gradient">BET MAGIC</h1>
              </div>
              <h2 className="welcome-text text-white mb-3">Temporarily Disconnected</h2>
              <p className="lead text-white-80">We'll Get You Back to Winning Soon</p>
            </div>
          </Container>
        </div>
        <Container className="pb-5">
          <div className="d-flex align-items-center justify-content-center" style={{ minHeight: "60vh" }}>
            <Card className="shadow border-0" style={{ width: "100%", maxWidth: "400px" }}>
              <CardBody className="px-lg-5 py-lg-5">
                <div className="text-center mb-4">
                  <div className="icon-wrapper mb-4">
                    <WifiOff size={48} className="text-danger" />
                  </div>
                  <h2 className="text-danger mb-3">You're Offline</h2>
                  <p className="text-muted mb-4">We can't reach our servers at the moment. Please check your internet connection and try again.</p>
                  {showRetryMessage && (
                    <Alert color="warning" className="mb-4">
                      Still offline. Please check your internet connection.
                    </Alert>
                  )}
                  <div className="troubleshooting-tips text-left mb-4">
                    <h5 className="text-primary mb-3">Try these steps:</h5>
                    <ul className="text-muted">
                      <li>Check your Wi-Fi connection</li>
                      <li>Check your mobile data connection</li>
                      <li>Verify your network settings</li>
                    </ul>
                  </div>
                  <Button color="primary" className="btn-icon" onClick={handleRetry}>
                    <RefreshCw size={16} className="mr-2" />
                    Retry Connection
                  </Button>
                </div>
              </CardBody>
            </Card>
          </div>
        </Container>
      </div>

      <style>
        {`
          .main-content {
            min-height: 100vh;
            position: relative;
            overflow: hidden;
          }

          .animated-background {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 0;
            background: linear-gradient(135deg, #1a1f4c 0%, #172b4d 100%);
            overflow: hidden;
          }

          .light-effect {
            position: absolute;
            width: 200%;
            height: 200%;
            top: -50%;
            left: -50%;
            background: radial-gradient(circle at center, rgba(255,255,255,0.05) 0%, transparent 50%);
            animation: rotate 45s linear infinite;
          }

          .particles {
            position: absolute;
            width: 100%;
            height: 100%;
            overflow: hidden;
            z-index: 1;
          }

          .particle {
            position: absolute;
            background: rgba(255,255,255,0.1);
            border-radius: 50%;
            pointer-events: none;
          }

          ${[...Array(30)]
            .map(
              (_, i) => `
                .particle-${i + 1} {
                  width: ${4 + Math.random() * 6}px;
                  height: ${4 + Math.random() * 6}px;
                  top: ${Math.random() * 100}vh;
                  left: ${Math.random() * 100}vw;
                  opacity: ${0.3 + Math.random() * 0.5};
                  animation: moveParticle-${i + 1} ${30 + Math.random() * 40}s linear infinite;
                }

                @keyframes moveParticle-${i + 1} {
                  0% {
                    transform: translate(0, 0);
                  }
                  20% {
                    transform: translate(${Math.random() < 0.5 ? "-" : ""}${50 + Math.random() * 100}px, 
                                      ${Math.random() < 0.5 ? "-" : ""}${50 + Math.random() * 100}px);
                  }
                  40% {
                    transform: translate(${Math.random() < 0.5 ? "-" : ""}${50 + Math.random() * 100}px, 
                                      ${Math.random() < 0.5 ? "-" : ""}${50 + Math.random() * 100}px);
                  }
                  60% {
                    transform: translate(${Math.random() < 0.5 ? "-" : ""}${50 + Math.random() * 100}px, 
                                      ${Math.random() < 0.5 ? "-" : ""}${50 + Math.random() * 100}px);
                  }
                  80% {
                    transform: translate(${Math.random() < 0.5 ? "-" : ""}${50 + Math.random() * 100}px, 
                                      ${Math.random() < 0.5 ? "-" : ""}${50 + Math.random() * 100}px);
                  }
                  100% {
                    transform: translate(${Math.random() < 0.5 ? "-" : ""}${50 + Math.random() * 100}px, 
                                      ${Math.random() < 0.5 ? "-" : ""}${50 + Math.random() * 100}px);
                  }
                }`
            )
            .join("\n")}

          .glow-effects {
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: 0;
          }

          .glow {
            position: absolute;
            border-radius: 50%;
            filter: blur(80px);
            opacity: 0.15;
            animation: glow-float 20s ease-in-out infinite;
          }

          .glow-1 {
            top: 20%;
            left: 20%;
            width: 500px;
            height: 500px;
            background: #5e72e4;
            animation-delay: 0s;
          }

          .glow-2 {
            bottom: 20%;
            right: 20%;
            width: 600px;
            height: 600px;
            background: #825ee4;
            animation-delay: -5s;
          }

          .glow-3 {
            top: 50%;
            left: 50%;
            width: 400px;
            height: 400px;
            background: #4756b6;
            animation-delay: -10s;
          }

          @keyframes glow-float {
            0%, 100% {
              transform: translate(0, 0) scale(1);
            }
            33% {
              transform: translate(-50px, 50px) scale(1.1);
            }
            66% {
              transform: translate(50px, -50px) scale(0.9);
            }
          }

          @keyframes rotate {
            from {
              transform: rotate(0deg);
            }
            to {
              transform: rotate(360deg);
            }
          }

          .container {
            position: relative;
            z-index: 2;
          }

          .card {
            background-color: #ffffff;
            border-radius: 1rem;
            transform: translateY(0);
            transition: transform 0.2s ease;
          }

          .card-body {
            padding: 3rem 2rem;
          }

          .icon-wrapper {
            background: rgba(245, 54, 92, 0.1);
            width: 96px;
            height: 96px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 auto;
          }

          .troubleshooting-tips ul {
            list-style: none;
            padding-left: 0;
          }

          .troubleshooting-tips li {
            position: relative;
            padding-left: 1.5rem;
            margin-bottom: 0.5rem;
          }

          .troubleshooting-tips li:before {
            content: "•";
            color: #5e72e4;
            position: absolute;
            left: 0;
            font-size: 1.2rem;
          }

          .btn-icon {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            padding: 0.75rem 1.5rem;
            font-weight: 600;
            transition: all 0.15s ease;
          }

          .btn-icon:hover {
            transform: translateY(-1px);
          }

          @media (max-width: 768px) {
            .card-body {
              padding: 2rem 1.5rem;
            }

            .icon-wrapper {
              width: 72px;
              height: 72px;
            }

            .icon-wrapper svg {
              width: 36px;
              height: 36px;
            }
          }

          @media (prefers-reduced-motion: reduce) {
            .light-effect,
            .particle,
            .glow {
              animation: none;
            }
          }

          .alert {
            border: 0;
            border-radius: 0.375rem;
          }

          .header-wrapper {
            position: relative;
            padding-top: 4rem;
            padding-bottom: 2rem;
            z-index: 1;
          }

          .header-container {
            position: relative;
            z-index: 1;
          }

          .header-content {
            max-width: 700px;
            margin: 0 auto;
            padding: 2rem 1rem;
          }

          .brand-logo h1 {
            font-size: 3.5rem;
            font-weight: 800;
            letter-spacing: 2px;
            margin: 0;
          }

          .text-gradient {
            background: linear-gradient(45deg, #5e72e4, #825ee4);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
          }

          .welcome-text {
            font-size: 2.5rem;
            font-weight: 600;
            line-height: 1.2;
          }

          .text-white-80 {
            color: rgba(255, 255, 255, 0.8);
          }

          @media (max-width: 768px) {
            .header-wrapper {
              padding-top: 3rem;
              padding-bottom: 1.5rem;
            }

            .brand-logo h1 {
              font-size: 2.5rem;
            }

            .welcome-text {
              font-size: 2rem;
            }
          }

          @media (max-width: 480px) {
            .header-wrapper {
              padding-top: 2rem;
              padding-bottom: 1rem;
            }

            .brand-logo h1 {
              font-size: 2rem;
            }

            .welcome-text {
              font-size: 1.5rem;
            }
          }
        `}
      </style>
    </>
  );
};

export default Offline;
