import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import profileCoverImage from "../../assets/img/theme/profile-cover.jpg";

const UserHeader = ({ firstName, user }) => {
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const timer = setInterval(() => setCurrentTime(new Date()), 1000);
    return () => clearInterval(timer);
  }, []);

  const timeOfDay = () => {
    const hour = currentTime.getHours();
    if (hour < 12) return "Good morning";
    if (hour < 17) return "Good afternoon";
    return "Good evening";
  };

  const formatTime = (date) => {
    return date.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    });
  };

  const formatDate = (date) => {
    return date.toLocaleDateString("en-US", {
      weekday: "long",
      month: "long",
      day: "numeric",
    });
  };

  const formatLastLogin = (timestamp) => {
    if (!timestamp) return "First time here";

    const lastLogin = timestamp.toDate();
    const now = new Date();
    const diffInHours = Math.floor((now - lastLogin) / (1000 * 60 * 60));

    if (diffInHours < 1) {
      const diffInMinutes = Math.floor((now - lastLogin) / (1000 * 60));
      return `${diffInMinutes} minute${diffInMinutes === 1 ? "" : "s"} ago`;
    }
    if (diffInHours < 24) {
      return `${diffInHours} hour${diffInHours === 1 ? "" : "s"} ago`;
    }
    if (diffInHours < 48) {
      return "Yesterday";
    }
    return lastLogin.toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    });
  };

  return (
    <div className="user-header">
      <div className="header-background" style={{ backgroundImage: `url(${profileCoverImage})` }}>
        <div className="overlay-gradient" />
      </div>

      <Container className="header-content" fluid>
        <Row className="align-items-start">
          <Col lg="6" md="8">
            <div className="header-text">
              <span className="greeting-badge">
                <i className="fas fa-sun greeting-icon" />
                {timeOfDay()}
              </span>
              <h1 className="display-name">{firstName}</h1>
              <p className="header-description">Welcome to your profile dashboard. Here you can manage your account settings and preferences.</p>
            </div>
          </Col>
          <Col lg="6" md="12">
            <div className="widgets-container">
              {/* Time Widget */}
              <div className="widget time-widget">
                <div className="widget-content">
                  <i className="fas fa-clock widget-icon" />
                  <div className="widget-info">
                    <span className="widget-title">Local Time</span>
                    <span className="widget-value">{formatTime(currentTime)}</span>
                    <span className="widget-subtitle">{formatDate(currentTime)}</span>
                  </div>
                </div>
              </div>

              {/* Last Login Widget */}
              <div className="widget login-widget">
                <div className="widget-content">
                  <i className="fas fa-user-clock widget-icon" />
                  <div className="widget-info">
                    <span className="widget-title">Last Login</span>
                    <span className="widget-value">{formatLastLogin(user?.lastLogin)}</span>
                    <span className="widget-subtitle">Welcome back!</span>
                  </div>
                </div>
              </div>

              {/* Account Status Widget */}
              <div className="widget status-widget">
                <div className="widget-content">
                  <i className="fas fa-shield-alt widget-icon" />
                  <div className="widget-info">
                    <span className="widget-title">Account Status</span>
                    <span className="widget-value">{user?.stripeId ? "Premium Active" : "Free"}</span>
                    <span className="widget-subtitle">{user?.stripeId ? "Manage subscription in settings" : "No subscription"}</span>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      <style>
        {`
          .user-header {
            position: relative;
            min-height: 500px;
            padding: 6rem 0 8rem 0;
            overflow: hidden;
          }

          .header-background {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 0;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
          }

          .overlay-gradient {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(
              150deg,
              rgba(94, 114, 228, 0.97) 0%,
              rgba(130, 94, 228, 0.97) 100%
            );
            z-index: 1;
          }

          .header-content {
            position: relative;
            z-index: 3;
          }

          .header-text {
            color: white;
            opacity: 0;
            transform: translateY(20px);
            animation: fadeInUp 0.6s ease forwards;
          }

          .greeting-badge {
            display: inline-flex;
            align-items: center;
            background: rgba(255, 255, 255, 0.1);
            backdrop-filter: blur(10px);
            padding: 0.5rem 1rem;
            border-radius: 100px;
            font-size: 0.875rem;
            font-weight: 500;
            margin-bottom: 1.5rem;
            transition: all 0.3s ease;
          }

          .greeting-badge:hover {
            background: rgba(255, 255, 255, 0.2);
            transform: translateY(-1px);
          }

          .greeting-icon {
            margin-right: 0.5rem;
            font-size: 1rem;
          }

          .display-name {
            font-size: 3.5rem;
            font-weight: 700;
            margin: 0;
            line-height: 1.2;
            letter-spacing: -0.02em;
            margin-bottom: 1rem;
          }

          .header-description {
            font-size: 1.125rem;
            opacity: 0.9;
            max-width: 600px;
            line-height: 1.6;
            margin: 0;
          }

          /* Widgets Styling */
          .widgets-container {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
            gap: 1rem;
            margin-top: 2rem;
            opacity: 0;
            transform: translateY(20px);
            animation: fadeInUp 0.6s ease forwards 0.2s;
          }

          .widget {
            background: rgba(255, 255, 255, 0.1);
            backdrop-filter: blur(10px);
            border-radius: 1rem;
            padding: 1.25rem;
            transition: all 0.3s ease;
          }

          .widget:hover {
            background: rgba(255, 255, 255, 0.15);
            transform: translateY(-2px);
          }

          .widget-content {
            display: flex;
            align-items: center;
            gap: 1rem;
          }

          .widget-icon {
            font-size: 1.5rem;
            color: rgba(255, 255, 255, 0.9);
            background: rgba(255, 255, 255, 0.1);
            width: 48px;
            height: 48px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 12px;
          }

          .widget-info {
            display: flex;
            flex-direction: column;
          }

          .widget-title {
            font-size: 0.875rem;
            color: rgba(255, 255, 255, 0.7);
            margin-bottom: 0.25rem;
          }

          .widget-value {
            font-size: 1.125rem;
            font-weight: 600;
            color: white;
            margin-bottom: 0.25rem;
          }

          .widget-subtitle {
            font-size: 0.75rem;
            color: rgba(255, 255, 255, 0.6);
          }

          @keyframes fadeInUp {
            from {
              opacity: 0;
              transform: translateY(20px);
            }
            to {
              opacity: 1;
              transform: translateY(0);
            }
          }

          /* Responsive Styles */
          @media (max-width: 991.98px) {
            .user-header {
              min-height: 400px;
              padding: 4rem 0 6rem 0;
            }

            .display-name {
              font-size: 2.5rem;
            }

            .header-description {
              font-size: 1rem;
            }

            .widgets-container {
              grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
              margin-top: 3rem;
            }
          }

          @media (max-width: 767.98px) {
            .user-header {
              min-height: 350px;
              padding: 3rem 0 5rem 0;
            }

            .display-name {
              font-size: 2rem;
            }

            .greeting-badge {
              font-size: 0.8125rem;
              padding: 0.4rem 0.875rem;
            }

            .header-description {
              font-size: 0.9375rem;
            }

            .widgets-container {
              grid-template-columns: 1fr;
              gap: 0.75rem;
            }

            .widget {
              padding: 1rem;
            }

            .widget-icon {
              width: 40px;
              height: 40px;
              font-size: 1.25rem;
            }
          }

          @media (prefers-reduced-motion: reduce) {
            .header-text,
            .widgets-container,
            .greeting-badge,
            .widget {
              animation: none;
              transition: none;
            }
          }

          @media (max-width: 768px) {
            .user-header {
              padding: 2rem 0;
              margin-bottom: 2rem;
            }

            .widgets-container {
              grid-template-columns: 1fr;
              gap: 1rem;
              margin-top: 1.5rem;
            }

            .status-widget {
              margin-bottom: 3rem;
            }
          }

          @media (max-width: 480px) {
            .user-header {
              padding: 1.5rem 0;
              margin-bottom: 1.5rem;
            }

            .status-widget {
              margin-bottom: 4rem;
            }
          }
        `}
      </style>
    </div>
  );
};

export default UserHeader;
