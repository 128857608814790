import React from "react";
import { Card, CardBody, CardTitle, Row, Col } from "reactstrap";
import "./ModernCard.css";

const StatsIndicator = ({ type, percentage }) => (
  <div className="stats-indicator mt-3">
    <span className={`stats-badge ${type === "OVER" ? "bg-success" : type === "UNDER" ? "bg-danger" : "bg-primary"}`}>
      <i className={type === "OVER" ? "fas fa-arrow-up" : type === "UNDER" ? "fas fa-arrow-down" : ""} />
      <span className="percentage">{percentage.toFixed(1)}%</span>
    </span>
    <span className="stats-label">{type}</span>
  </div>
);

const OriginalTotalCard = ({ total = "N/A", statsType = "", percentage = 0 }) => (
  <Card className="modern-card">
    <CardBody>
      <Row className="align-items-center">
        <Col>
          <CardTitle tag="h6" className="text-uppercase text-muted mb-2 font-weight-bold">
            Original Total
          </CardTitle>
          <span className="card-value">{total}</span>
        </Col>
        <Col xs="auto">
          <div className="icon-container">
            <i className="fas fa-chart-line" />
          </div>
        </Col>
      </Row>
      {statsType && <StatsIndicator type={statsType} percentage={percentage} />}
    </CardBody>
  </Card>
);

export default OriginalTotalCard;
