import React from "react";
import { Route, Routes, Navigate } from "react-router";

import "./assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./assets/scss/argon-dashboard-react.scss";

import DashboardLayout from "./layouts/Dashboard.js";
import AuthLayout from "./layouts/Auth.js";
import "./firebase/config.js";
import { AuthWrapper } from "./helpers/AuthWrapper";
import OfflineHandler from "./components/OfflineHandler";
import Offline from "./views/examples/Offline";
import SubscriptionExpired from "./views/examples/SubscriptionExpired";
import { useScrollToTop } from "./hooks/useScrollToTop";

const App = () => {
  useScrollToTop();

  return (
    <>
      <OfflineHandler />
      <AuthWrapper>
        <Routes>
          <Route path="/offline" element={<Offline />} />
          <Route path="/subscription-expired" element={<SubscriptionExpired />} />
          <Route path="dashboard">
            <Route path="*" element={<DashboardLayout />} />
          </Route>
          <Route path="auth">
            <Route path="*" element={<AuthLayout />} />
          </Route>
          <Route path="/" element={<Navigate to="/auth/login" replace />} />
        </Routes>
      </AuthWrapper>
    </>
  );
};

export default App;
