export const GAME_STATUS_STYLES = {
  Ongoing: {
    color: "success",
    text: "LIVE",
    className: "bg-success",
    useTime: false,
  },
  Finished: {
    color: "danger",
    text: "FINAL",
    className: "bg-danger",
    useTime: false,
  },
  "Not Started": {
    color: "warning",
    text: "UPCOMING",
    className: "bg-warning",
    useTime: true,
  },
};
