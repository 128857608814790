import { useState, useEffect } from "react";
import { doc, onSnapshot, query, where, orderBy, collection } from "firebase/firestore";
import { firebaseDb } from "../firebase/config";

export const useFetchGame = (gameId) => {
  const [game, setGame] = useState(null);
  const [totals, setTotals] = useState([]);
  const [spreads, setSpreads] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!gameId) {
      setError("Game ID is required");
      setIsLoading(false);
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      // Setup listeners for game, totals, and spreads
      const gameDoc = doc(firebaseDb, "games", gameId);
      const totalsQuery = query(collection(firebaseDb, "totals"), where("gameId", "==", gameDoc), orderBy("createdAt", "asc"));
      const spreadsQuery = query(collection(firebaseDb, "spreads"), where("gameId", "==", gameDoc), orderBy("createdAt", "asc"));

      // Subscribe to game document
      const unsubGame = onSnapshot(
        gameDoc,
        (doc) => {
          if (doc.exists()) {
            setGame({ id: doc.id, ...doc.data() });
          } else {
            setError("Game not found");
          }
        },
        (err) => {
          console.error("Error fetching game:", err);
          setError("Failed to fetch game data");
        }
      );

      // Subscribe to totals collection
      const unsubTotals = onSnapshot(
        totalsQuery,
        (snapshot) => {
          const totalsData = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setTotals(totalsData);
        },
        (err) => {
          console.error("Error fetching totals:", err);
          setError("Failed to fetch totals data");
        }
      );

      // Subscribe to spreads collection
      const unsubSpreads = onSnapshot(
        spreadsQuery,
        (snapshot) => {
          const spreadsData = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setSpreads(spreadsData);
        },
        (err) => {
          console.error("Error fetching spreads:", err);
          setError("Failed to fetch spreads data");
        }
      );

      // Set loading to false once initial data is fetched
      setIsLoading(false);

      // Cleanup function to unsubscribe from all listeners
      return () => {
        unsubGame();
        unsubTotals();
        unsubSpreads();
      };
    } catch (err) {
      console.error("Error setting up listeners:", err);
      setError("Failed to initialize data listeners");
      setIsLoading(false);
    }
  }, [gameId]); // Only re-run if gameId changes

  return {
    game,
    totals,
    spreads,
    error,
    isLoading,
  };
};
